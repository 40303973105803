import { LoggerCore } from '@video/log-client';
import {
  MediaState, StoredKNParticipant, StoredKNRoom, TileSortOptionTypes,
} from 'store/types';
import livelyLogger from 'utils/logger';
import dropDuplicates from './dropDuplicates';
import { getFirstName, getLastName, hasLastName } from './stringUtils';

export type ParticipantSortingType = 'GridView' | 'FilmStrip';

export interface SortByDisplayNameOptions {
  singleNamesLast?: boolean
  sortByFirstNameOnly?: boolean
}

/**
 * Sorts an array of CCServiceRoomParticipants by their displayName.
 *
 * If the name has space, sorts by last name, else sorts by first (only) name.
 * Optionally, put users with only one name at the end of the array and sort alphabetically there.
 */
export const sortByDisplayName = <T extends {
  displayName: string,
  userId?: string,
}>(array: T[], {
    singleNamesLast = false,
    sortByFirstNameOnly = false,
  }: SortByDisplayNameOptions = {}) => array.slice().sort((a, b) => {
    const aFirstName = getFirstName(a.displayName).toLowerCase();
    const aLastName = getLastName(a.displayName).toLowerCase();
    const bFirstName = getFirstName(b.displayName).toLowerCase();
    const bLastName = getLastName(b.displayName).toLowerCase();
    if (singleNamesLast) {
      if (hasLastName(a.displayName) && !hasLastName(b.displayName)) return -1;
      if (!hasLastName(a.displayName) && hasLastName(b.displayName)) return 1;
    }

    if (!sortByFirstNameOnly) {
    // sort by last names
      if (aLastName < bLastName) return -1;
      if (aLastName > bLastName) return 1;
    }

    // if last names equal, sort by first name
    if (aFirstName < bFirstName) return -1;
    if (aFirstName > bFirstName) return 1;

    // if last names and first names equal, sort by userId if supplied
    if (a.userId !== undefined && b.userId !== undefined) {
      if (a.userId < b.userId) return -1;
      if (a.userId > b.userId) return 1;
    }

    return 0;
  });

/**
* Sorts an array of CCServiceRoomParticipants by their firstName and lastName.
* If the user is missing firstName and lastName, it attempts to sort by displayName.
* Uses userId as a tie-breaker.
*
* When sorting by displayName, if the name has space, sorts by last name, else sorts by first (only) name.
*
* Optionally, put users with only one name at the end of the array and sort alphabetically there.
*/
export const sortByNameData = <T extends {
  firstName: string,
  lastName: string,
  displayName: string,
  userId?: string,
}>(array: T[], {
    singleNamesLast = false,
  }: SortByDisplayNameOptions = {}) => array.slice().sort((a, b) => {
    let aFirstName = a.firstName.toLowerCase();
    let aLastName = a.lastName.toLowerCase();
    const aDisplayName = a.displayName.toLowerCase();
    let aIsSingleName = false;

    // only use displayName if firstName and lastName are both empty
    if (!aFirstName && !aLastName) {
      // when there is only one name in the displayName, assume that it's the user's lastName
      if (hasLastName(aDisplayName)) {
        aFirstName = getFirstName(aDisplayName).toLowerCase();
      } else {
        aIsSingleName = true;
        aFirstName = '';
      }
      aLastName = getLastName(aDisplayName).toLowerCase();
    }

    let bFirstName = b.firstName.toLowerCase();
    let bLastName = b.lastName.toLowerCase();
    const bDisplayName = b.displayName.toLowerCase();
    let bIsSingleName = false;

    // only use displayName if firstName and lastName are both empty
    if (!bFirstName && !bLastName) {
      // when there is only one name in the displayName, assume that it's the user's lastName
      if (hasLastName(bDisplayName)) {
        bFirstName = getFirstName(bDisplayName).toLowerCase();
      } else {
        bIsSingleName = true;
        bFirstName = '';
      }
      bLastName = getLastName(bDisplayName).toLowerCase();
    }

    if (singleNamesLast) {
      if (!aIsSingleName && bIsSingleName) return -1;
      if (aIsSingleName && !bIsSingleName) return 1;
    }

    // sort by last names
    if (aLastName < bLastName) return -1;
    if (aLastName > bLastName) return 1;

    // if last names equal, sort by first name
    if (aFirstName < bFirstName) return -1;
    if (aFirstName > bFirstName) return 1;

    // if last names and first names equal, sort by userId if supplied
    if (a.userId !== undefined && b.userId !== undefined) {
      if (a.userId < b.userId) return -1;
      if (a.userId > b.userId) return 1;
    }

    return 0;
  });

const formatWarning = (warning: string) => `sortParticipants: ${warning}`;
export const WARNING_SCREENSHARER_NOT_IN_PARTICIPANTS = formatWarning('Screensharer not found in CC Service participants');
export const WARNING_INVALID_USER_ID = formatWarning('Invalid currentUserId supplied');
export const WARNING_INVALID_SCREENSHARERS = formatWarning('Invalid screenSharers value supplied');
export const WARNING_NO_PRESENTER_USER = formatWarning('No presenterUser supplied to the FilmStrip sort');

export type SortParticipantsArgs = {
  gridParticipants: StoredKNRoom['participants'],
  mediaState: MediaState,
  screenSharers: string[],
  ownerId: string,
  currentUserId: string,
  sortingType: ParticipantSortingType,
  presenterId?: string | null,
  logger?: LoggerCore,
  tileSorting: TileSortOptionTypes
  includeDisabledCameras: boolean
}

/**
 * Pure function that receives all necessary data from hook and returns
 * the sorted list of participants. This util is designed to work with
 * both GridView and PresenterView.
 *
 * For simplicity, we add in each user wherever they meet the criteria to be
 * in a particular location (duplicating is fine), and then at the end we keep
 * only the first instance of that user and remove all of the rest of the duplicates.
 * By extension, this means placement in the sortedArray earlier on takes a higher
 * priority over placement later on in the array.
 *
 * Exists as a separate function here for ease of testing.
 *
 * GridView ordering:
 * -- For participants only:
 *    -- Owner feed or owner shared content as first on top left for grid
 * -- For all users:
 *    -- Shared content by participants in order of initiated
 *    -- If "Include Disabled Cameras" is false
 *        -- Split users into camera on/off groups
 *        -- For each group, sort participants in alphabetical order by selected tileSorting
 *        -- Group with cameras on will come first
 *    -- If "Include Disabled Cameras" is true
 *        -- Participants in alphabetical order by selected tileSorting
 *
 * FilmStrip ordering:
 * -- For all users:
 *    -- If the main presenter is screensharing, show the presenter's video feed as first tile
 *    -- Shared content by participants in order of initiated
 *    -- If "Include Disabled Cameras" is false
 *        -- Split users into camera on/off groups
 *        -- For each group, sort participants in alphabetical order by selected tileSorting
 *        -- Group with cameras on will come first
 *    -- If "Include Disabled Cameras" is true
 *        -- Participants in alphabetical order by selected tileSorting
 */
export default function sortParticipants({
  gridParticipants,
  mediaState,
  screenSharers,
  ownerId,
  currentUserId,
  sortingType,
  presenterId,
  logger = livelyLogger, // only necessary to provide this when testing
  tileSorting,
  includeDisabledCameras,
}: SortParticipantsArgs) {
  // input validation
  if (!currentUserId || typeof currentUserId !== 'string') logger.warn(WARNING_INVALID_USER_ID);
  if (!Array.isArray(screenSharers) || (screenSharers.length && typeof screenSharers[0] !== 'string')) logger.warn(WARNING_INVALID_SCREENSHARERS);
  if (sortingType === 'FilmStrip' && !presenterId) logger.warn(WARNING_NO_PRESENTER_USER);

  // make a copy so as not to mutate the original participants object
  const participants = { ...gridParticipants };
  const isOwner = ownerId && currentUserId ? ownerId === currentUserId : false;
  let sortedParticipants: StoredKNParticipant[] = [];
  const presenterUserIsSharing = presenterId ? screenSharers.includes(presenterId) : false;
  const currentUserIsSharing = screenSharers.includes(currentUserId);
  const sortOptions = { sortByFirstNameOnly: tileSorting === 'First name' };

  const currentUserParticipant = participants[currentUserId];

  // the current user's tile should not appear in the grid or in the filmstrip (exception screenshare content, handled below)
  delete participants[currentUserId];

  if (sortingType === 'FilmStrip' && presenterId && participants[presenterId]) {
    // if the presenter user (either participant or owner) is screensharing
    // their regular video feed should be the first one in the filmstrip
    if (presenterUserIsSharing) sortedParticipants.push(participants[presenterId]);
    // otherwise, the presenter's tile should not be in the film strip at all
    else delete participants[presenterId];
  }

  // if current user is sharing and not presenting, their screenshare content is the first tile
  if (currentUserIsSharing && !(sortingType === 'FilmStrip' && presenterId === currentUserId) && currentUserParticipant) {
    sortedParticipants.push(currentUserParticipant);
  }

  // participants should see the owner's tile next (or first in GridView)
  if (!isOwner && participants[ownerId]) sortedParticipants.push(participants[ownerId] as StoredKNParticipant);

  // screensharers should come next
  screenSharers.forEach((userId) => {
    if (participants[userId]) sortedParticipants.push(participants[userId] as StoredKNParticipant);
    // do not log warning if screensharer is currentUserId, since they shouldn't be in list of participants
    else if (userId !== currentUserId) logger.warn(WARNING_SCREENSHARER_NOT_IN_PARTICIPANTS);
  });

  if (!includeDisabledCameras) {
    let usersWithCameraOn: StoredKNParticipant[] = [];
    let usersWithCameraOff: StoredKNParticipant[] = [];
    // separate users into camera on/off arrays
    Object.keys(participants).forEach((userId) => {
      // check that we have the state of their audio/video
      if (!mediaState[userId] || mediaState[userId].videoOff) {
        // We have not received the user's player yet or their camera is off
        usersWithCameraOff.push(participants[userId] as StoredKNParticipant);
      } else {
        usersWithCameraOn.push(participants[userId] as StoredKNParticipant);
      }
    });

    // sort the camera on/off lists by name
    usersWithCameraOn = sortByDisplayName(usersWithCameraOn, sortOptions);
    usersWithCameraOff = sortByDisplayName(usersWithCameraOff, sortOptions);

    // add in users with cameras on, alphabetized
    sortedParticipants.push(...usersWithCameraOn);

    // add in users with camera off, alphabetized
    sortedParticipants.push(...usersWithCameraOff);
  } else {
    // add remaining users, sorted by display name
    sortedParticipants = sortedParticipants.concat(
      sortByDisplayName(Object.keys(participants).map(
        (userId) => participants[userId] as StoredKNParticipant,
      ), sortOptions),
    );
  }

  // filter any undefined users (just in case)
  // drop any duplicates (keep the element's FIRST instance in the array)
  const finalOrder = dropDuplicates(sortedParticipants.filter((user) => user), { mapping: (user) => user.userId });

  // return array of userIds
  return finalOrder.map((user) => user.userId);
}
