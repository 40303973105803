import { LoggerCore } from '@video/log-client';
import isEmpty from 'lodash/isEmpty';
import logger, { createLogger } from 'utils/logger';
import { PermissionEnum } from './permissionEnum';
import { PermissionStateEnum } from './permissionStateEnum';

/**
 * The Permissions API may not be available on all
 * major browsers. This boolean indicates whether
 * the API exists in the browser.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API
 */
export const getPermissionsApiAvailable = () => !!window?.navigator?.permissions;

function _formatLog(m: string) {
  return `BrowserPermissions: ${m}`;
}

/**
 * This is a wrapper for the Permissions API in order to get the
 * state of a given permission and listen for changes in its state.
 *
 * The Permissions API at the time of this writing is experimental
 * and not available on all major browsers. If it is not available,
 * this will warn via its logger, and any method that requires the
 * Permissions API will be a no-op.
 *
 * @example
 * import logger from 'utils/logger';
 * import { PermissionEnum, BrowserPermissions } from 'utils/browserPermissions';
 *
 * const browserPerm = new BrowserPermissions(logger)
 *
 * // The state is a member of PermissionStateEnum, a wrapper
 * // that describes the PermissionStatus's state.
 * // The perm is the original PermissionEnum passed, included
 * // to make listener callbacks more reusable.
 * const handlePermissionChange = (state, perm) => {
 *   console.log(`${perm.name} permission is ${state.name}`)
 *   if(state.isGranted){
 *      console.log('Success')
 *   }
 *   else if(state.isDenied){
 *     console.error('User denied permission')
 *   }
 * }
 *
 * // Wraps PermissionStatus.onchange
 * browserPerm.listen(PermissionEnum.MICROPHONE, handlePermissionChange)
 * browserPerm.listen(PermissionEnum.CAMERA, handlePermissionChange)
 * // The following line is idempotent, because only one listener is kept active per permission.
 * // This is done to reduce the need for cleanup logic when updating callbacks.
 * browserPerm.listen(PermissionEnum.CAMERA, handlePermissionChange)
 * browserPerm.listen(PermissionEnum.MIDI, handlePermissionChange)
 *
 * browserPerm.isListening() // true because at least one listener is active
 * browserPerm.isListening(PermissionEnum.MICROPHONE) // true
 * browserPerm.isListening(PermissionEnum.GEOLOCATION) // false
 *
 * browserPerm.stopListen(PermissionEnum.MICROPHONE)
 * browserPerm.stopListen() // all listeners stopped
 *
 * browserPerm.isListening() // false
 *
 * browserPerm.getState(PermissionEnum.MICROPHONE, (status) => {
 *  console.log(`Microphone permission is ${status.name}`)
 * })
 */
export default class BrowserPermissions {
  public constructor() {
    this._logger = createLogger(logger, 'BrowserPermissions');
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private _removeListenerMap: { [permName: string]: Function } = {};
  private _permissionsApiWarned = false;
  private _provisionalPermsWarned: Array<PermissionEnum> = [];
  private _logger: LoggerCore;

  public get available(): boolean {
    return this._checkIfPermApi();
  }

  /**
   * @public
   * Only allows for one listener per permission, so there is no
   * need to call stopListen every time the listener callback needs to
   * be updated. Does not call callback if permission state is unexpected
   * (PermissionStateEnum.UNKNOWN).
   *
   * @param perm Member of PermissionEnum, e.g. PermissionEnum.MICROPHONE
   * @param callback Passed member of PermissionStateEnum and the PermissionEnum requested on change
   */
  public async listen(
    perm: PermissionEnum,
    callback: (state: PermissionStateEnum, perm: PermissionEnum) => void,
  ) {
    const available = this._checkIfPermApi();
    if (!available) return;

    const valid = this._validatePermission(perm);
    if (!valid) return;

    this.stopListen(perm);

    try {
      const status = await this._getPermissionStatus(perm);
      if (!status) return;

      const listener = () => {
        const state = this._getPermissionStateFromStatus(status);
        this._logger.local(_formatLog('Change in permission state'), {
          permission: perm.name,
          state: state.name,
        });
        if (state !== PermissionStateEnum.UNKNOWN) callback(state, perm);
      };

      status.addEventListener('change', listener);
      this._removeListenerMap[perm.name] = () => status.removeEventListener('change', listener);
      this._logger.local(_formatLog('Listening for permission state change'), {
        permission: perm.name,
      });
    } catch (e) {
      this._logger.error(_formatLog('Error creating listener for permission'), { perm: perm.name, error: e as any });
    }
  }

  /**
   * @public
   * @param perm Optional - Member of PermissionEnum. If not given, stop all listeners
   */
  public stopListen(perm?: PermissionEnum): void {
    perm ? this._removeListener(perm.name) : this._removeAllListeners();
  }

  /**
   * @public
   * @param perm Optional - Member of PermissionEnum. If not given, return true if any listeners
   * @returns boolean
   */
  public isListening(perm?: PermissionEnum): boolean {
    return perm ? !!this._removeListenerMap[perm.name] : !isEmpty(this._removeListenerMap);
  }

  /**
   * @public
   * Get the current state of a permission. Will return PermissionStateEnum.UNKNOWN
   * if the PermissionsAPI is unavailable, the passed permission is PermissionEnum.UNKNOWN
   * or is an invalid value, or if the status fails, logging the reason as an error.
   *
   * @param perm Member of PermissionEnum (e.g. PermissionEnum.MICROPHONE)
   * @returns Promise: PermissionStateEnum (wrapper that describes the state of a permission)
   */
  public async getState(perm: PermissionEnum): Promise<PermissionStateEnum> {
    const available = this._checkIfPermApi();
    if (!available) return PermissionStateEnum.UNKNOWN;

    const valid = this._validatePermission(perm);
    if (!valid) return PermissionStateEnum.UNKNOWN;

    const status = await this._getPermissionStatus(perm);
    if (!status) return PermissionStateEnum.UNKNOWN;

    return this._getPermissionStateFromStatus(status);
  }

  /**
   * @private
   * Check if the permissions API is available and log warning one time if it is not.
   */
  private _checkIfPermApi(): boolean {
    const available = getPermissionsApiAvailable();
    if (!available && !this._permissionsApiWarned) {
      this._logger.warn(_formatLog('The Permissions API is not available on this browser. Cannot query or listen for permission changes'));
      this._permissionsApiWarned = true;
    }
    return available;
  }

  /**
   * @private
   * Check if a given permission is valid to use to get a PermissionStatus.
   * Must be a member of PermissionEnum that is not PermissionEnum.UNKNOWN
   */
  private _validatePermission(perm: PermissionEnum): boolean {
    const valid = (perm !== PermissionEnum.UNKNOWN && (perm instanceof PermissionEnum));
    if (!valid) {
      this._logger.error(_formatLog('Invalid permission received'), { perm: perm.name });
    } else if (perm.provisional) {
      if (!this._provisionalPermsWarned.includes(perm)) {
        this._logger.local(_formatLog(`Permission '${perm.name}' is provisional and subject to change.`));
        this._provisionalPermsWarned.push(perm);
      }
    }
    return valid;
  }

  /** calls a remove listener callback in removeListenerMap and deletes it */
  private _removeListener(key: string) {
    try {
      const removeListener = this._removeListenerMap[key];
      if (removeListener) {
        removeListener();
        delete this._removeListenerMap[key];
      }
    } catch (e) {
      this._logger.error(_formatLog('Error removing listener'), { key, error: e as any });
    }
  }

  /** Call _removeListener on each existing listener */
  private _removeAllListeners(): void {
    Object.keys(this._removeListenerMap).forEach((key) => this._removeListener(key));
  }

  /** Takes raw PermissionStatus from Permissions API and converts to PermissionStateEnum wrapper */
  private _getPermissionStateFromStatus(status: PermissionStatus): PermissionStateEnum {
    const permStateWrapper = PermissionStateEnum.getByState(status.state);
    if (permStateWrapper === PermissionStateEnum.UNKNOWN) {
      this._logger.error(_formatLog('Received unknown permission state from status'), { status: status.state });
    }
    return permStateWrapper;
  }

  /** Get raw PermissionStatus from Permission API, return null if failed */
  private async _getPermissionStatus(perm: PermissionEnum): Promise<PermissionStatus | null> {
    try {
      return await window.navigator.permissions.query({ name: perm?.name as PermissionName });
    } catch (e) {
      if (e instanceof TypeError) {
        this._logger.warn(_formatLog('Permission is not supported for query.'), { perm: perm.name, error: e } as any);
      } else {
        this._logger.error(_formatLog('Error querying for permission'), { perm: perm.name, error: e } as any);
      }
      return null;
    }
  }
}
