/**
 * Create encoding options object.
 * Optionally pass in normal values, usually from redux debugger state
 */
function createEncodingOptions(normal: { maxBitrate: number }[]) {
  return {
    1: normal.slice(0, 1),
    2: normal.slice(0, 2),
    3: normal,
  };
}

export default createEncodingOptions;
