import {
  AppThunkAction, IcebreakerAnswer, IcebreakerQuestionType, IcebreakerIndexes, IcebreakerParticipantResponseNotification,
} from 'store/types';
import logger from 'utils/logger';
import { MakeActionType } from 'utils/typeUtils';
import { roomRequest, setRoomError } from 'actions/sharedActions';
import { getStatusCodeFromError } from 'utils/errorUtils';
import { batch } from 'react-redux';
import { setAlert } from './alertActions';

export const reducerName = 'icebreakerState' as const;
export const SET_QUESTION_TYPE = `${reducerName}/SET_QUESTION_TYPE` as const;
export const SET_QUESTION_LABEL = `${reducerName}/SET_QUESTION_LABEL` as const;
export const ADD_ANSWER = `${reducerName}/ADD_ANSWER` as const;
export const SET_ICEBREAKER_LOADING = `${reducerName}/SET_ICEBREAKER_LOADING` as const;
export const SET_ICEBREAKER_OPEN = `${reducerName}/SET_ICEBREAKER_OPEN` as const;
export const SET_CORRECT_ANSWER = `${reducerName}/SET_CORRECT_ANSWER` as const;
export const UPDATE_ANSWER = `${reducerName}/UPDATE_ANSWER` as const;
export const RESET_ICEBREAKER_CREATION = `${reducerName}/RESET_ICEBREAKER_CREATION` as const;
export const ADD_ACTIVE_ICEBREAKER_RESULT = `${reducerName}/ADD_ACTIVE_ICEBREAKER_RESULT` as const;
export const ADD_ANSWERED_ICEBREAKER = `${reducerName}/ADD_ANSWERED_ICEBREAKER` as const;
export const SET_RESULTS_DISMISSED = `${reducerName}/SET_RESULTS_DISMISSED` as const;
export const RESET_ACTIVE_RESULTS = `${reducerName}/RESET_ACTIVE_RESULTS` as const;
export const TOGGLE_QUESTION_ANONYMOUS = `${reducerName}/TOGGLE_QUESTION_ANONYMOUS` as const;

export const setQuestionType = (questionType: IcebreakerQuestionType) => ({
  type: SET_QUESTION_TYPE,
  payload: {
    questionType,
  },
});

export const setQuestionLabel = (label: string) => ({
  type: SET_QUESTION_LABEL,
  payload: {
    label,
  },
});

export const toggleQuestionAnonymous = () => ({
  type: TOGGLE_QUESTION_ANONYMOUS,
});

export const setIcebreakerLoading = (loading: boolean) => ({
  type: SET_ICEBREAKER_LOADING,
  payload: {
    loading,
  },
});

export const addIcebreakerAnswer = (answer: IcebreakerAnswer) => ({
  type: ADD_ANSWER,
  payload: {
    answer,
  },
});

export const updateIcebreakerAnswer = (answerLabel: string, index: IcebreakerIndexes) => ({
  type: UPDATE_ANSWER,
  payload: {
    answerLabel,
    index,
  },
});

export const setIcebreakerOpen = (isIcebreakerOpen: boolean) => ({
  type: SET_ICEBREAKER_OPEN,
  payload: {
    isIcebreakerOpen,
  },
});

export const setCorrectAnswer = (isCorrect:boolean, correctIndex: IcebreakerIndexes, value:string) => ({
  type: SET_CORRECT_ANSWER,
  payload: {
    isCorrect,
    correctIndex,
    value,
  },
});

export const resetIcebreakerCreation = () => ({
  type: RESET_ICEBREAKER_CREATION,
});

export const _addActiveResult = (activeResult:IcebreakerParticipantResponseNotification) => ({
  type: ADD_ACTIVE_ICEBREAKER_RESULT,
  payload: {
    activeResult,
  },
});

export const addAnsweredIcebreaker = (id:string) => ({
  type: ADD_ANSWERED_ICEBREAKER,
  payload: {
    id,
  },
});

export const setIcebreakerResultsDismissed = (resultsDismissed:boolean) => ({
  type: SET_RESULTS_DISMISSED,
  payload: {
    resultsDismissed,
  },
});

export const resetActiveResults = () => ({
  type: RESET_ACTIVE_RESULTS,
});

export type IcebreakerAction = MakeActionType<[
  typeof setQuestionType,
  typeof setQuestionLabel,
  typeof toggleQuestionAnonymous,
  typeof setIcebreakerLoading,
  typeof addIcebreakerAnswer,
  typeof setIcebreakerOpen,
  typeof setCorrectAnswer,
  typeof updateIcebreakerAnswer,
  typeof resetIcebreakerCreation,
  typeof _addActiveResult,
  typeof addAnsweredIcebreaker,
  typeof setIcebreakerResultsDismissed,
  typeof resetActiveResults,
]>

/**
 * Wrapper that checks if a response is a duplicate
 */
export const addActiveResult = (activeResult:IcebreakerParticipantResponseNotification):AppThunkAction => (dispatch, getState) => {
  const results = getState().icebreakerState.activeResults;
  let found = false;

  results.forEach((result:IcebreakerParticipantResponseNotification) => {
    if (result.userId === activeResult.userId) {
      found = true;
    }
  });

  if (found) {
    logger.warn('Duplicate answer', { ...activeResult });
    return;
  }

  dispatch(_addActiveResult(activeResult));
};

export interface PostResponse {
  data: {
    id: string
  }
}

/**
 * Sends a POST request to create the question then a PUT request to publish to room state
 */
export const submitIcebreaker = ():AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const roomId = state.roomState.room.id;
  const {
    label, questionType, answers, anonymousAnswers,
  } = state.icebreakerState.question;
  dispatch(setIcebreakerLoading(true));

  try {
    logger.info('Creating icebreaker');
    const response = await dispatch(roomRequest({
      method: 'POST',
      url: `/rooms/${roomId}/question`,
      data: {
        label,
        questionType,
        anonymousAnswers,
        answerCharLimit: 0,
        answers: answers ? answers.filter((answer) => !!answer.label.length) : null,
      },
    })) as unknown as PostResponse | null;

    if (response && response.data.id) {
      const questionSessionId = response.data.id;
      logger.info('Publishing icebreaker', { questionSessionId });
      await dispatch(roomRequest({
        method: 'PUT',
        url: `/rooms/${roomId}/question/${questionSessionId}/publish`,
        data: {
          label,
          questionType,
          anonymousAnswers,
          answerCharLimit: 0,
          answers,
        },
      }));

      dispatch(resetIcebreakerCreation());
    }
  } catch (error) {
    logger.error('Error creating icebreaker', { error } as any);
    dispatch(setRoomError('Error creating icebreaker'));
  } finally {
    dispatch(setIcebreakerLoading(false));
  }
};

/**
 * Submit an answer to the active icebreaker
 */
export const submitIcebreakerAnswer = (
  questionSessionId:string,
  answerId = 0,
  shortAnswer = '',
  isAnonymous = false,
):AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const roomId = state.roomState.room.id;
  const recipients = [];
  if (isAnonymous) {
    recipients.push(state.roomState.room.ownerId);
    if (state.loginState.user?.userId) { recipients.push(state.loginState.user.userId); }
  }

  dispatch(setIcebreakerLoading(true));

  try {
    logger.info('Submitting icebreaker answer', { questionSessionId });
    await dispatch(roomRequest({
      method: 'POST',
      url: `/rooms/${roomId}/question/${questionSessionId}`,
      data: {
        answerId,
        response: shortAnswer,
        recipients,
      },
    }));
  } catch (error) {
    const code = getStatusCodeFromError(error);
    logger.error('Error submitting icebreaker answer', { error } as any);
    if (code === 422) {
      dispatch(setAlert('room', 'You have already answered this question'));
    }
  } finally {
    dispatch(setIcebreakerLoading(false));
    dispatch(addAnsweredIcebreaker(questionSessionId));
  }
};

/**
 * Switch icebreaker to inactive and display results to users
 */
export const closeIcebreaker = (questionSessionId:string):AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const roomId = state.roomState.room.id;
  batch(() => {
    dispatch(setIcebreakerLoading(true));
    dispatch(setIcebreakerOpen(false));
    dispatch(setCorrectAnswer(false, 0, ''));
  });
  try {
    logger.info('Closing icebreaker', { questionSessionId });
    await dispatch(roomRequest({
      method: 'PUT',
      url: `/rooms/${roomId}/question/${questionSessionId}/close`,
    }));
  } catch (error) {
    logger.error('Error closing icebreaker', { error } as any);
    dispatch(setRoomError('Error closing icebreaker'));
  } finally {
    dispatch(setIcebreakerLoading(false));
  }
};
