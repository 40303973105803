import produce from 'immer';
import { ErrorAction, RESET_ERROR_MESSAGE, SET_INCOMPATIBLE_ERROR } from 'actions/errorMessageActions';
import {
  SET_ERROR_MESSAGE,
  RESET_ROOM,
  SET_JOIN_ROOM_ERROR,
  SET_ROOM_ERROR,
  SET_BREAKOUT_GROUP_ERROR,
  SET_LAUNCH_GROUPS_ERROR,
  SET_TIMEOUT_ERROR,
  SET_DEVICE_ERROR,
  SharedAction,
  SET_ATTENDANCE_ERROR,
} from 'actions/sharedActions';
import { ErrorMessageState } from 'store/types';

export const initialState: ErrorMessageState = {
  errorPage: '',
  room: '',
  join: '',
  breakoutGroups: '',
  launchGroups: '',
  timeout: '',
  device: '',
  attendance: '',
  incompatibleError: '',
};

const reducer = produce((draft: ErrorMessageState, action: ErrorAction | SharedAction) => {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      draft.errorPage = action.payload.errorPageError;
      break;
    case SET_ROOM_ERROR:
      draft.room = action.payload.roomError;
      break;
    case SET_JOIN_ROOM_ERROR:
      draft.join = action.payload.joinError;
      break;
    case SET_BREAKOUT_GROUP_ERROR:
      draft.breakoutGroups = action.payload.breakoutGroupsError;
      break;
    case SET_LAUNCH_GROUPS_ERROR:
      draft.launchGroups = action.payload.launchGroupsError;
      break;
    case SET_TIMEOUT_ERROR:
      draft.timeout = action.payload.timeoutError;
      break;
    case SET_DEVICE_ERROR:
      draft.device = action.payload.deviceError;
      break;
    case SET_ATTENDANCE_ERROR:
      draft.attendance = action.payload.error;
      break;
    case RESET_ERROR_MESSAGE:
      draft[action.payload.type] = initialState[action.payload.type];
      break;
    case RESET_ROOM:
      Object.assign(draft, {
        ...initialState,
        errorPage: draft.errorPage,
        join: draft.join,
      });
      break;
    case SET_INCOMPATIBLE_ERROR:
      draft.incompatibleError = action.payload.incompatibleError;
      break;
    default:
      break;
  }
}, initialState);

export default reducer;
