import { MakeActionType } from 'utils/typeUtils';
import { AppThunkAction, BitrateOptions } from 'store/types';
import { batch } from 'react-redux';

export const reducerName = 'debugState' as const;
export const SET_BITRATE_OPTIONS = `${reducerName}/SET_BITRATE_OPTIONS` as const;
export const SORT_BITRATE_OPTIONS = `${reducerName}/SORT_BITRATE_OPTIONS` as const;
export const SET_MAX_BITRATE_OPTION = `${reducerName}/SET_MAX_BITRATE_OPTION` as const;
export const SET_AUDION_DTX = `${reducerName}/SET_AUDION_DTX` as const;
export const SET_CODEC_ENABLED = `${reducerName}/SET_CODEC_ENABLED` as const;
export const SET_AUDIO_DEBUG_MODAL_OPEN = `${reducerName}/SET_AUDIO_DEBUG_MODAL_OPEN` as const;
export const TOGGLE_FILTERS_MODAL = `${reducerName}/TOGGLE_FILTERS_MODAL` as const;
export const TOGGLE_SHOW_BITRATE_LEVELS = `${reducerName}/TOGGLE_SHOW_BITRATE_LEVELS` as const;
export const TOGGLE_BITRATE_OPTIONS_MODAL = `${reducerName}/TOGGLE_BITRATE_OPTIONS_MODAL` as const;
export const TOGGLE_DEBUG_MODAL = `${reducerName}/TOGGLE_DEBUG_MODAL` as const;
export const TOGGLE_GRAFANA_OPTIONS_MODAL = `${reducerName}/TOGGLE_GRAFANA_OPTIONS_MODAL` as const;
export const TOGGLE_ENABLE_GRAFANA_LINKS = `${reducerName}/TOGGLE_ENABLE_GRAFANA_LINKS` as const;
export const TOGGLE_ENABLE_MOCK_PARTICIPANTS = `${reducerName}/TOGGLE_ENABLE_MOCK_PARTICIPANTS` as const;
export const TOGGLE_ENABLE_SANDBOX = `${reducerName}/TOGGLE_ENABLE_SANDBOX` as const;
export const TOGGLE_ENABLE_DELETE_ALL_ROOMS = `${reducerName}/TOGGLE_ENABLE_DELETE_ALL_ROOMS` as const;
export const TOGGLE_ENABLE_ARCADE = `${reducerName}/TOGGLE_ENABLE_ARCADE` as const;
export const TOGGLE_ENABLE_CHALKBOARD_STREAM = `${reducerName}/TOGGLE_ENABLE_CHALKBOARD_STREAM` as const;
export const TOGGLE_EMIT_CLIENT_STATS = `${reducerName}/TOGGLE_EMIT_CLIENT_STATS` as const;
export const TOGGLE_SMART_SHAPE_TOOL = `${reducerName}/TOGGLE_SMART_SHAPE_TOOL` as const;
export const TOGGLE_ENABLE_BITRATE_SWITCHING = `${reducerName}/TOGGLE_ENABLE_BITRATE_SWITCHING` as const;

export const setBitrateOptions = (bitrateOptions: BitrateOptions) => ({
  type: SET_BITRATE_OPTIONS,
  payload: { bitrateOptions },
});

export const setMaxBitrateOption = (payload: { option: keyof BitrateOptions, bitrate: number }) => ({
  type: SET_MAX_BITRATE_OPTION,
  payload,
});

export const setAudioDtx = (payload: { isAudioDTX: boolean }) => ({
  type: SET_AUDION_DTX,
  payload,
});

export const setCodecEnabled = (codecEnabled: boolean) => ({
  type: SET_CODEC_ENABLED,
  payload: { codecEnabled },
});

export const setIsAudioDebugModalOpen = (open: boolean) => ({
  type: SET_AUDIO_DEBUG_MODAL_OPEN,
  payload: { open },
});

export const toggleBitrateOptionsModal = (open?: boolean) => ({
  type: TOGGLE_BITRATE_OPTIONS_MODAL,
  payload: { open },
});

export const toggleDebugModal = (open?: boolean) => ({
  type: TOGGLE_DEBUG_MODAL,
  payload: { open },
});

export const toggleGrafanaOptionsModal = (open?: boolean) => ({
  type: TOGGLE_GRAFANA_OPTIONS_MODAL,
  payload: { open },
});

export const toggleShowBitrateLevels = (show?: boolean) => ({
  type: TOGGLE_SHOW_BITRATE_LEVELS,
  payload:
   { show },
});

export const toggleFiltersModal = (show?: boolean) => ({
  type: TOGGLE_FILTERS_MODAL,
  payload:
   { show },
});

export const toggleEnableGrafanaLinks = (enable?: boolean) => ({
  type: TOGGLE_ENABLE_GRAFANA_LINKS,
  payload: { enable },
});

export const toggleEnableAddMockParticipants = (enable?: boolean) => ({
  type: TOGGLE_ENABLE_MOCK_PARTICIPANTS,
  payload: { enable },
});

export const toggleEnableSandbox = (enable?: boolean) => ({
  type: TOGGLE_ENABLE_SANDBOX,
  payload: { enable },
});

export const toggleEnableDeleteAllRooms = (enable?: boolean) => ({
  type: TOGGLE_ENABLE_DELETE_ALL_ROOMS,
  payload: { enable },
});

export const toggleEnableArcade = (enable?: boolean) => ({
  type: TOGGLE_ENABLE_ARCADE,
  payload: { enable },
});

export const toggleEnableChalkboardStream = (enable?: boolean) => ({
  type: TOGGLE_ENABLE_CHALKBOARD_STREAM,
  payload: { enable },
});

export const toggleEmitVideoClientStats = (enable: boolean) => ({
  type: TOGGLE_EMIT_CLIENT_STATS,
  payload: { enable },
});

export const toggleSmartShapeTool = (enable: boolean) => ({
  type: TOGGLE_SMART_SHAPE_TOOL,
  payload: { enable },
});

export const toggleEnableBitrateSwitching = (enable: boolean) => ({
  type: TOGGLE_ENABLE_BITRATE_SWITCHING,
  payload: { enable },
});

export type DebugAction = MakeActionType<[
  typeof setBitrateOptions,
  typeof setMaxBitrateOption,
  typeof setAudioDtx,
  typeof setCodecEnabled,
  typeof setIsAudioDebugModalOpen,
  typeof toggleShowBitrateLevels,
  typeof toggleBitrateOptionsModal,
  typeof toggleDebugModal,
  typeof toggleGrafanaOptionsModal,
  typeof toggleEnableGrafanaLinks,
  typeof toggleEnableAddMockParticipants,
  typeof toggleEnableSandbox,
  typeof toggleEnableDeleteAllRooms,
  typeof toggleEnableArcade,
  typeof toggleEnableChalkboardStream,
  typeof toggleEmitVideoClientStats,
  typeof toggleFiltersModal,
  typeof toggleSmartShapeTool,
  typeof toggleEnableBitrateSwitching,
]>

/**
 * If all debug tools are enabled, turns all off, else turns on all debug tools.
 * i.e. if all tools are enabled but one, it will ENABLE all rather than DISABLE all.
 */
export const toggleAllDebugTools = (): AppThunkAction => (dispatch, getState) => {
  const {
    enableMockParticipants,
    enableBitrateLevels,
    enableSandbox,
    enableGrafanaLinks,
    enableDeleteAllRooms,
    enableArcade,
  } = getState().debugState;

  const allEnabled = enableMockParticipants
    && enableBitrateLevels
    && enableSandbox
    && enableGrafanaLinks
    && enableDeleteAllRooms
    && enableArcade;

  const toggleBoolean = !allEnabled;

  batch(() => {
    dispatch(toggleEnableAddMockParticipants(toggleBoolean));
    dispatch(toggleShowBitrateLevels(toggleBoolean));
    dispatch(toggleEnableSandbox(toggleBoolean));
    dispatch(toggleEnableGrafanaLinks(toggleBoolean));
    dispatch(toggleEnableDeleteAllRooms(toggleBoolean));
    dispatch(toggleEnableArcade(toggleBoolean));
  });
};

export const sortBitrateOptions = (): AppThunkAction => (dispatch, getState) => {
  const { encoderBitrateOptions } = getState().debugState;
  const sortedBitrates = Object.values(encoderBitrateOptions).sort((a, b) => (a.maxBitrate > b.maxBitrate ? 1 : -1));
  dispatch(setBitrateOptions({
    Low: sortedBitrates[0],
    Medium: sortedBitrates[1],
    High: sortedBitrates[2],
  }));
};
