import { createSelector } from 'reselect';
import { StoreState } from 'store/types';

export const getEncoderBitrateOptions = (state: StoreState) => state.debugState.encoderBitrateOptions;

export const getCodecEnabled = (state: StoreState) => state.debugState.codecEnabled;

export const getIsAudioDTX = (state: StoreState) => state.debugState.isAudioDTX;

export const getIsAudioDebugModalOpen = (state: StoreState) => state.debugState.isAudioDebugModalOpen;

export const getShowBitrateLevels = (state: StoreState) => state.debugState.enableBitrateLevels;

export const getIsBitrateOptionsModalOpen = (state: StoreState) => state.debugState.isBitrateOptionsModalOpen;

export const getIsDebugModalOpen = (state: StoreState) => state.debugState.isDebugModalOpen;

export const getIsGrafanaOptionsModalOpen = (state: StoreState) => state.debugState.isGrafanaOptionsModalOpen;

export const getIsFiltersModalOpen = (state: StoreState) => state.debugState.isFiltersModalOpen;

export const getGrafanaLinksEnabled = (state: StoreState) => state.debugState.enableGrafanaLinks;

export const getMockParticipantsEnabled = (state: StoreState) => state.debugState.enableMockParticipants;

export const getSandboxEnabled = (state: StoreState) => state.debugState.enableSandbox;

export const getDeleteAllRoomsEnabled = (state: StoreState) => state.debugState.enableDeleteAllRooms;

export const getArcadeEnabled = (state: StoreState) => state.debugState.enableArcade;

export const getChalkboardStreamEnabled = (state: StoreState) => state.debugState.enableChalkboardStream;

export const getEmitVDCStatsEnabled = (state: StoreState) => state.debugState.enableEmitVideoClientStats;

export const getSmartToolEnabled = (state: StoreState) => state.debugState.enableSmartTool;

export const getBitrateSwitchingEnabled = (state: StoreState) => state.debugState.enableBitrateSwitching;

export const getAllDebugToolsEnabled = createSelector(
  getMockParticipantsEnabled,
  getSandboxEnabled,
  getGrafanaLinksEnabled,
  getShowBitrateLevels,
  getDeleteAllRoomsEnabled,
  getArcadeEnabled,
  (
    mockParticipantsEnabled,
    sandboxEnabled,
    grafanaLinksEnabled,
    bitrateOverlaysEnabled,
    deleteAllRoomsEnabled,
    arcadeEnabled,
  ) => (
    mockParticipantsEnabled && sandboxEnabled && grafanaLinksEnabled && bitrateOverlaysEnabled && deleteAllRoomsEnabled && arcadeEnabled
  ),
);
