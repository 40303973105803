/* eslint-disable max-len */

import { types } from '@video/video-client-web';
import { EnumBase, WithUnknownString } from 'utils/enum';
import logger from 'utils/logger';

type DisplayCaptureSurfaceType =
  'monitor' |
  'window' |
  'application' |
  'browser'

type TrackingName = 'Entire Screen' | 'Window' | 'Tab' | 'Application' | ''

export interface DisplayCaptureSurface {
  type: WithUnknownString<DisplayCaptureSurfaceType>;
  name: TrackingName;
  description: string;
}

/**
 * For enumeration the type of display being shared by a screensharer.
 * See spec for reference: https://w3c.github.io/mediacapture-screen-share/#idl-def-DisplayCaptureSurfaceType.window
 */
export class DisplayCaptureSurfaceEnum extends EnumBase implements DisplayCaptureSurface {
  public static readonly MONITOR = new DisplayCaptureSurfaceEnum('monitor', 'Entire Screen', 'A monitor display surface, physical display, or collection of physical displays');
  public static readonly WINDOW = new DisplayCaptureSurfaceEnum('window', 'Window', 'A window display surface, or single application window');
  public static readonly APPLICATION = new DisplayCaptureSurfaceEnum('application', 'Application', 'An application display surface, or entire collection of windows for an application');
  public static readonly BROWSER = new DisplayCaptureSurfaceEnum('browser', 'Tab', 'A browser display surface, or single browser window');
  public static readonly UNKNOWN = new DisplayCaptureSurfaceEnum('UNKNOWN', '', 'N/A');

  protected constructor(
    public type: WithUnknownString<DisplayCaptureSurfaceType>,
    public name: TrackingName,
    public description: string,
  ) {
    super();
  }

  public static getByDisplaySurface(displaySurface: string): DisplayCaptureSurfaceEnum {
    return this.asArray().find(({ type }) => type === displaySurface) || this.UNKNOWN;
  }

  public static getFromMediaStream(
    mediaStream: types.MediaStream,
  ): DisplayCaptureSurfaceEnum {
    try {
      // use standard MediaStream to check instance
      if (!(mediaStream instanceof MediaStream)) {
        throw new Error(`Did not receive MediaStream (received: ${typeof mediaStream})`);
      }

      for (const track of mediaStream.getTracks()) {
        // TS Hack, displaySurface not supported yet
        const { displaySurface } = track.getSettings() as any;
        if (displaySurface) {
          return this.getByDisplaySurface(displaySurface);
        }
      }
    } catch (error) {
      logger.error('Error getting display capture surface from media stream', { error } as any);
    }
    return this.UNKNOWN;
  }

  public static asArray() {
    return super.asArray() as Array<DisplayCaptureSurfaceEnum>;
  }

  public static asObject() {
    return super.asObject() as { [id: string]: DisplayCaptureSurfaceEnum };
  }
}
