import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import LivelyChat, { LivelyEmojiInfo } from '@livelyvideo/chat-core';
import {
  PlayerUiState, EncoderUiState, types, CallState,
} from '@video/video-client-web';
import {
  NoExtraProperties, Overwrite,
} from 'utils/typeUtils';
import { DisplayCaptureSurfaceEnum } from 'utils/screenshare';
import { BrowserPermissions, PermissionStateEnum } from 'utils/browserPermissions';
import { RoomLayoutEnum } from 'actions/roomActions';
import {
  CCServiceAllViewAttendanceSession,
  CCServiceSoundPreference, CCServiceSoundPreferencesEnum, CCServiceUserViewAttendanceSession,
} from 'utils/ccService/types';
import WS from '@livelyvideo/hub-websocket';
import { RouterState } from 'connected-react-router';
import { SoundPlayer } from 'utils/soundPlayer';
import { SoundPlayerOptions } from 'utils/soundPlayer/soundPlayerOptions';
import { ComponentProps, ReactElement } from 'react';
import { BreakpointObj } from 'theme/ui/breakpoints';
import { IBreakPointResults, IBrowser } from 'redux-responsive/types';
import { GifResult, GifsResult } from '@giphy/js-fetch-api';
import { Any, O } from 'ts-toolbelt';
// import { ForceImmediateUpdate } from 'hooks/useDelayedSelector';
import {
  KNBreakoutRoom, KNKnocker, KNParticipant, KNRoom,
} from 'utils/roomState/knats';
import {
  Filter, Filters, FiltersKeys, TensorFlowBackends,
} from 'filters';
import { ChalkboardItem } from 'components/Chalkboard/itemData';

/* ************* */
/* BROWSER STATE */
/* ************* */

type BrowserState = IBrowser<BreakpointObj> & {
  maxWidth: IBreakPointResults<BreakpointObj>,
  minWidth: IBreakPointResults<BreakpointObj>,
};

/* ************* */
/* NETWORK STATE */
/* ************* */
export interface NetworkState {
  online: boolean,
}

/* **************** */
/* ATTENDANCE STATE */
/* **************** */

/** "All Guests" view | "Individual Guest" view */
export type AttendanceView = 'all' | 'user';

/* START: All View Types ########################################## */
/** Formats start time as a Date object for more efficient renders */
export type AllViewSession = CCServiceAllViewAttendanceSession & {
  startTimeDate: Date,
};

/** Stores sessions as a map of sessionId -> session data */
export interface AllViewSessions {
  [sessionId: string]: AllViewSession,
}

export type AllViewDetailActionType = 'hovered' | 'focused';

export type AllViewDetailStatus = {
  [ActionType in AllViewDetailActionType]: boolean
};

export interface AllViewDetailInfo {
  userId: string,
  sessionId: string,
}

/** State relating to the "All Guests" View of the Attendance Reports */
export interface AttendanceAllViewState {
  allViewSessionId: string,
  allViewPrevSessionsLink: string,
  allViewSessionsFetched: boolean,
  allViewSessionsLoading: boolean,
  allViewSessionsError: string,
  allViewSessions: AllViewSessions,
  allViewUserIdLabels: string[],
  allViewSessionIdLabels: string[],
  allViewDetailHovered: AllViewDetailInfo | null,
  allViewDetailFocused: AllViewDetailInfo | null,
  allViewCSVDataLoading: boolean,
}
/* END: All View Types ########################################## */

/* START: User View Types ########################################## */
/** Formats start time as a Date object for more efficient renders */
export type UserViewSession = CCServiceUserViewAttendanceSession & {
  startTimeDate: Date,
};

export interface UserViewSessions {
  [sessionId: string]: UserViewSession,
}

/** State relating to the "Individual Guest"/"User" View of the Attendance Reports */
export interface AttendanceUserViewState {
  userViewPrevSessionsLink: string,
  userViewSessionsFetched: boolean,
  userViewSessionsLoading: boolean,
  userViewSessionsError: string,
  userViewSessions: UserViewSessions,
  userViewSessionIdLabels: string[],
  userViewCSVDataLoading: boolean,
  userViewDisplayName: string,
  userViewFirstName: string,
  userViewLastName: string,
}
/* END: User View Types ########################################## */

export type AttendanceState = AttendanceAllViewState & AttendanceUserViewState;

/* ********************* */
/* BREAKOUT GROUPS STATE */
/* ********************* */
export interface GroupIdToUserIdsMap {
  [groupId: string]: string[];
}

export interface GroupIdToNameMap {
  [groupId: string]: string;
}

export interface NameAndUsersGroup {
  name: string;
  users: string[];
}

export enum GroupSelectionTypeEnum {
  RANDOM = 'random',
  MANUAL = 'manual',
  NOT_SET = ''
}

export interface BreakoutGroupsSettings {
  canScreenShare: boolean,
  canChat: boolean,
  autoMove: boolean,
  warnBeforeClose: boolean,
  warnTime: number,
  canMove: boolean,
  canJoinOthers: boolean,
}

export type LaunchedGroup = Overwrite<KNBreakoutRoom, { users: string[], id: string | number }>

export type LaunchedGroups = { [groupId: string]: LaunchedGroup }

export interface BreakoutGroupsState {
  groupSelectionType: GroupSelectionTypeEnum, // todo: rename
  groups: GroupIdToUserIdsMap,
  groupNames: GroupIdToNameMap,
  settings: BreakoutGroupsSettings,
  isLaunched: boolean,
  isGroupsVisible: boolean,
  launchedGroups: LaunchedGroups,
  recallTimer: Date | null,
  loading: boolean,
  loadingMessage: string,
  launchComplete: boolean,
  joinAudioId: string | null,
  isRecallingGroups: boolean,
  recentGroupsLoading: boolean,
}

/* ***************** */
/* PREFERENCES STATE */
/* ***************** */

export type SoundPreference = Omit<CCServiceSoundPreference, 'id' | 'userId' | 'created' | 'updated'>

export type SoundPreferences = {
  [key in CCServiceSoundPreferencesEnum]: SoundPreference;
}

export const tileSortOptions = ['Last name', 'First name'] as const;
export type TileSortOptionTypes = typeof tileSortOptions[number];

export interface PreferencesState {
  soundPreferencesLoading: boolean;
  soundPreferences: SoundPreferences;
  tileSort: TileSortOptionTypes,
  tileSortIncludeDisabledCameras: boolean,
}

/* *********** */
/* LOGIN STATE */
/* *********** */

export type UserType = 'conference-owner' | 'conference-participant';

export enum LoginTypesEnum {
  /**
   * User who has officially signed up, either via Native or Google account creation.
   */
  AUTH_USER = 'authUser',

  /**
   * User who has been given a temporary account "behind the scenes" on the Join page.
   */
  TEMP_USER = 'tempUser',

  /**
   * User who is not valid, but does have some authentication credentials.
   * This type of user should only occur when the user is in the state of being
   * transitioned into either a valid AUTH/TEMP_USER or completely blank-slate ANON_USER.
   *
   * i.e. either:
   * The user is being re-verified by the useSyncAuthState hook if their credentials are valid enough to re-verify,
   * OR they are about to be logged out completely if their credentials cannot be used to re-verify.
   */
  UNKNOWN = 'unknown',

  /**
   * A completely anonymous user: they have no login data at all (user, JWT, livelyToken, or livelyTokenExp).
   * This should be the standard loginType for a brand new user or a user who has just been logged out.
   *
   * If the user has SOME auth data, but their data is insufficient to re-verify their authentication credentials, they
   * are temporarily considered to be UNKNOWN and are immediately logged out, at which point they become an ANON_USER.
   */
  ANON_USER = 'anonUser',
}

export type Scopes = [UserType, 'chat'] | [UserType];

export type UserSource = 'google' | 'guest' | 'native';

/**
 * CC Service only returns 'google' for Google users. We supplement with
 * 'chalkcast' in the absence of a provider property.
 */
export type UserProvider = 'google' | 'chalkcast';

export type UserPhotos = { value: string }[];

export interface LivelyToken {
  data: { displayName: string },
  expire: string,
  scopes: Scopes,
  token: string,
}

/**
 * User who has been signed up for a CC account behind-the-scenes on the Join page.
 */
export interface TempUser {
  displayName: string,
  exp: number,
  source: UserSource,
  userId: string,
  provider: UserProvider,
  newGoogleUser: boolean,
}

/**
 * User who has explicitly signed up for a CC account with an email and password.
 */
export type NativeUser = TempUser & {
  sanitizedEmail: string,
}

/**
 * User who has signed up for a CC account through Google.
 */
export type GoogleUser = NativeUser & {
  photos: UserPhotos,
}

/**
 * Google and native users are both considered AuthUsers.
 */
export type AuthUser = GoogleUser | NativeUser;

export type MockParticipant = Required<KNParticipant> & { mock: true };

/**
 * A generic user. Can be any login type.
 */
export type User = TempUser | NativeUser | GoogleUser;

export type ParticipantFormFields = 'displayName' | 'school';

export interface LoginState {
  loading: boolean,
  error: string,
  user: User | null,
  livelyToken: string | null,
  livelyTokenExp: string | null,
  token: string | null,
  joinedRoom: string | null,
  authFormError: string | null,
  authFormSending: boolean,
  registrationCompleteVisible: boolean,
  participantForm: {
    [key in ParticipantFormFields]: string
  }
  loginType: LoginTypesEnum,
  fetchingUser: boolean,
  fetchingTokens: boolean,
  redirectAfterLoginPath: string
}

/* ********** */
/* ROOM STATE */
/* ********** */

export type RoomPermissionName = 'screenShare' | 'broadcastMessage' | 'directMessage' | 'privateNudge';

export interface RoomPermission {
  permission: RoomPermissionName,
  permissionDescription: string,
  permissionGranted: 0 | 1,
  permissionId: number,
}

export type RoomPermissions = {
  [name in RoomPermissionName]: RoomPermission;
}

export interface OwnerBroadcastLoading {
  [userIdAndMessageType: string]: boolean,
}

export interface LivelyRoomToken {
  data: { displayName: string },
  expire: string,
  scopes: Scopes,
  token: string,
}

export type Socket = WS | null;

export type StoredKNParticipant = Required<KNParticipant>

export type StoredKNKnocker = Required<KNKnocker>

/**
 * Room state as received from CC Service (with slight modifications):
 * The properties "id", "callId", and "calledOnId" are overwritten here
 * to include more possible values.
 */
export type StoredKNRoom = Overwrite<Required<KNRoom>, {
  callId: string | false | null, // default = false. Will be null if owner leaves room
  participants: { [userId: string]: StoredKNParticipant },
  knockers: { [userId: string]: StoredKNKnocker | undefined },
  breakoutRooms: { [groupId: string]: KNBreakoutRoom }
}>

export interface RoomState {
  room: StoredKNRoom,
  permissionsLoading: boolean,
  permissionsUpdating: boolean,
  permissions: RoomPermissions,
  ownerId: string | null,
  ownerName: string | null,
  layout: RoomLayoutEnum,
  prevUserSelectedLayout: RoomLayoutEnum,
  presenterId: string | null,
  isRosterVisible: boolean,
  socket: Socket,
  isWSReady: boolean,
  loading: boolean,
  ownerBroadcastLoading: OwnerBroadcastLoading,
  roomJoinedTimestamp: number,
  dropdownOpen: boolean,
  livelyRoomToken: LivelyRoomToken,
  fullscreenUserId: string,
  isJoinable: boolean,
  isRoomLockLoading: boolean,
  isLockedFromJoining: boolean,
  isRequireLoginLoading: boolean,
  isDisabled: boolean,
  heartbeatFailure: boolean,
  initialRoomStateReceived: boolean,
  previouslyAdmitted: boolean,
  isChaperoneUpdateLoading: boolean,
}

/* ************ */
/*  NUDGE STATE */
/* ************ */

export interface Nudge {
  message: string,
  gifId: string,
  emoji: string,
  toUserId: string,
  fromUserId: string,
}

export interface Nudges {
  [userId: string]: Nudge | undefined,
}

export interface NudgeState {
  nudgingUserId: string,
  selectedNudgeGif: GifResult['data'] | null,
  selectedNudgeEmoji: string,
  nudges: Nudges,
}

/* ********** */
/* JOIN STATE */
/* ********** */

export interface JoinState {
  roomNameForRemovedModal: string,
  isNewDevice: boolean,
}

/* *********** */
/* ALERT STATE */
/* *********** */

export type AlertType = 'join' | 'room';

export type AlertState = {
  [type in AlertType]: string;
}

/* ****************** */
/* NOTIFICATION STATE */
/* ****************** */

export type NotificationType = 'room';

export enum NotificationComponentEnum {
  REMOVE_PARTICIPANT = 'RemoveParticipantNotification',
  INVITE_LINK = 'InviteUsersLink',
}

export type NotificationComponent = {
  component: NotificationComponentEnum,
  props?: { [key: string]: any }
}

export type NotificationMessage = string | NotificationComponent;

export type NotificationState = {
  [type in NotificationType]: NotificationMessage;
}

/* ********** */
/* CHAT STATE */
/* ********** */
export interface ChatPosition {
  href: string;
  length: number;
  positions: number[];
  type: string; // ? could be constrained to union/enum?
}
export interface ChatMetadata {
  senderUserId: string;
  tempId: string;
  breakoutRoomId?: string;
  recipientUserId?: string;
}

export type EveryoneChatMetadata = Omit<ChatMetadata, 'breakoutRoomId' | 'recipientUserId'>
export type BreakoutGroupChatMetadata = Omit<Required<ChatMetadata>, 'recipientUserId'>
export type DirectMessageChatMetadata = Omit<Required<ChatMetadata>, 'breakoutRoomId'>

/**
 * An official chat message received from the chat client.
 */
export type RealChatMessage = NoExtraProperties<{
  actor: {
    avatar: string;
    color: string;
    id: string;
    role: string;
    roomuserMetadata: { [key: string]: any } | null; // ? Unknown type (have not seen non-null value)
    url: string;
    userMetadata: {
      displayName: string;
    };
    username: string;
  };
  emoticons: []; // ? Unknown type (have not seen item value)
  id: string;
  length: number;
  links: ChatPosition[];
  mentions: [];
  message: string;
  // Note: metadata only nullable due to old messages. If sufficient time passes for old chat histories to clear, null could be removed
  metadata: ChatMetadata | null;
  mode: string;
  pin: boolean;
  positions: { [pos: number]: ChatPosition };
  recipient: string | null;
  tags: [];
  timestamp: number;
  version: string;
  whisper: boolean;
}>

/**
 * Placeholder chat message put in state by ChalkCast when a user sends a message.
 * This temporary message remains in state until the "real" chat message is received
 * via the chatClient 'chat' event, at which point the temp message is replaced with
 * the "real" message.
 */
export type TempChatMessage = NoExtraProperties<{
  id: string,
  actor: {
    id: string,
    userMetadata: {
      displayName: string,
    },
  },
  message: string,
  timestamp: number,
  whisper: boolean,
  metadata: ChatMetadata,
  unsent: boolean,
}>;

/**
 * Any chat message found in the chat state. Can either be a temporary,
 * placeholder chat message or a valid chat message as received from chatClient.
 */

export const CONVO_ID_NOT_SELECTED = '' as const;
export const CONVO_ID_EVERYONE = 'Everyone' as const;

export type LivelyChatMessage = RealChatMessage | TempChatMessage;

export const isRealChatMessage = (msg: LivelyChatMessage): msg is RealChatMessage => (msg as RealChatMessage).positions !== undefined;

export const CHAT_DM_PREFIX = 'dm-' as const;
export const CHAT_BG_PREFIX = 'breakoutGroup-' as const;

export type ConversationId = typeof CONVO_ID_NOT_SELECTED | typeof CONVO_ID_EVERYONE |
  `${typeof CHAT_BG_PREFIX}${string}` | `${typeof CHAT_DM_PREFIX}${string}`

export interface EmojisState {
  [category: string]: { [id: string]: LivelyEmojiInfo }
}
export interface ChatState {
  chatClient: LivelyChat | null,
  messages: LivelyChatMessage[],
  unseenMessages: { [convoId: string]: { messageId: string, count: number } },
  chatInput: string,
  showEmojis: boolean,
  emojis: EmojisState,
  isChatVisible: boolean,
  selectedConversationId: ConversationId,
  recentMessage: LivelyChatMessage | null,
  parsedLimitReached: boolean,
  submitDisabled: boolean,
}

/* ************* */
/* ENCODER STATE */
/* ************* */

export type MediaStreamController = types.MediaStreamController | null;

export type Broadcast = types.BroadcastAPI | null;

export type UiState = EncoderUiState | null;

export type Call = CallState | null;

export type VideoClient = types.VideoClientAPI | null;

/**
 * Types of user devices stored in Redux.
 */
export type DeviceIdTypes = 'videoDeviceId' | 'audioDeviceId';

/**
 * Strings referring to the user's audio/video devices as stored in Redux.
 */
export type DeviceIds = { [idType in DeviceIdTypes]: string | null }

/** These ids represent the various elements/locations FilterVideo can be rendered
 * Assigns hierarchy of priorities when an the Filter encoder is rendered in multiple places at once (top variant = highest priority) */
export enum EncoderRenderIdsEnum {
  FILTERS_MODAL,
  SETTINGS,
  PREFERENCES_MODAL,
  DEVICE_SELECTION,
  TILE,
  PREVIEW,
  CONTROL_BAR,
  JOIN_PAGE,
}

/** Converts the integer value of `EncoderRenderIdsEnum` into its string equivalent */
export const toFilterRenderIdsEnumKey = (
  encoderRenderId: EncoderRenderIdsEnum,
): keyof typeof EncoderRenderIdsEnum => (
  EncoderRenderIdsEnum[encoderRenderId] as keyof typeof EncoderRenderIdsEnum
);

/** EncoderRenderIdsEnum as an array of KEY strings (not indexes), sorted by their
 * order in the Enum (top variant = highest priority = index 0 in array) */
export const EncoderRenderIdsEnumAsArray: (keyof typeof EncoderRenderIdsEnum)[] = Object.entries(EncoderRenderIdsEnum)
  .filter((entry) => !Number.isNaN(parseInt(entry[0], 10)))
  .map((entry) => entry[1] as keyof typeof EncoderRenderIdsEnum);

/** Map of encoderRenderId -> is id currently being rendered */
export type EncoderRenderIds = O.Writable<{
  [key in keyof typeof EncoderRenderIdsEnum]: boolean;
}>;

/**
 * This state shouldn't change at all. BrowserPermissions is a singleton and
 * should not be changed or reset after it is initialized.
 */
export interface StaticEncoderState {
  readonly browserPermissions: BrowserPermissions,
}

/**
 * This is copy/paste from VDC, they do not export it
 */
export interface DominantSpeaker {
  userId: string;
  displayName: string;
  peerId: string;
  streamName: string;
  producerId: string;
}

/**
 * This state is mutable but should not be reset on a ROOM_RESET event.
 */
export type NoResetEncoderState = {
  mediaControllerInitialized: boolean,
  isVideoPaused: boolean | null,
  isAudioMuted: boolean | null,
  devicePermission: PermissionStateEnum,
  videoDeviceIdPref: string | null,
  audioDeviceIdPref: string | null,
  videoMediaStreamControllerLoading: boolean,
  filterMediaStreamControllerLoading: boolean,
  videoMediaStreamController: MediaStreamController,
  videoUiState: UiState,
  filterMediaStreamController: MediaStreamController,
  filterUiState: UiState,
  videoClient: VideoClient,
  callState: CallState | null,
  broadcast: Broadcast,
} & DeviceIds;

/**
 * This state is free to be changed and can be reset on a ROOM_RESET event.
 */
export type MutableEncoderState = {
  isRetrying: boolean,
  numBitrates: 1 | 2 | 3,
  hasTriedUpshift: boolean,
  upshiftUnsuccessfulTime: number,
  dominantSpeaker: DominantSpeaker | null,
  videoMediaStreamControllerError: string | null,
  encoderRenderIds: EncoderRenderIds,
  filterMaxBitrate: number,
  filterMediaStreamControllerError: string,
};

export type EncoderState = MutableEncoderState & NoResetEncoderState & StaticEncoderState;

/* ****************** */
/* SCREEN SHARE STATE */
/* ****************** */

export interface ScreenShareState {
  broadcast: Broadcast,
  screenShareMediaStreamController: MediaStreamController,
  screenShareMediaStreamControllerError: unknown,
  screenShareUiState: UiState,
  isScreenCapture: boolean,
  displayCaptureSurface: DisplayCaptureSurfaceEnum,
  swappedScreenSharePreview: boolean,
}

/* ************ */
/* FILTER STATE */
/* ************ */

/** Settings that sync up internal Filter class state with Redux. Should not be reset on a ROOM_RESET event */
export interface FilterSettings {
  currentFilterKey: FiltersKeys,
  enabledPublicFilters: Filters[],
  cannyEdgeDetectionThreshold: number,
  tensorFlowBackend: TensorFlowBackends,
  enableFaceDetection: boolean,
  maxFrameRate: number,
  predictionInterval: number,
  croppedCanvasSize: number,
}

/** This state is free to be changed and can be reset on a ROOM_RESET event. */
export interface MutableFilterState {
  filter: Filter | null,
  destCanvas: HTMLCanvasElement | null,
  srcVideo: HTMLVideoElement | null,
  isFiltering: boolean,
  isFilterLoading: boolean,
}

export type FilterState = MutableFilterState & FilterSettings;

/* *************** */
/* DASHBOARD STATE */
/* *************** */

export interface DashboardRoom {
  id: number,
  slug: string,
  description: string, // not currently used
  ownerId: number,
  currentSessionId: string, // not currently used
  inactive: 0 | 1,
  name: string,
  created: string,
  updated: string,
  lastSession: string,
  isLocked: 0 | 1,
  roster?: {
    displayName: string,
    userId: string,
  }[],
}

export interface DashboardState {
  myRooms: DashboardRoom[],
  invitedRooms: DashboardRoom[],
  isCreatingRoom: boolean,
  creatingRoomLoading: boolean,
  roomDeleteLoading: boolean, /** @todo move out of dashboard */
  nameErrors: { [key: string]: string | null } | null,
  newRoomNameError: { error: string, erroneousName: string, roomId: string },
  // modal state
  roomSettingsRoomId: string, /** @todo move out of dashboard */
  roomSettingsRoomName: string, /** @todo move out of dashboard */
  roomDeleteRoomId: string, /** @todo move out of dashboard */
  accountVisible: boolean,
  appSettingsModalOpen: boolean,
  nextMyRoomsUrl: string,
  nextInvitedRoomsUrl: string,
}

/* ******************** */
/* PASSWORD RESET STATE */
/* ******************** */

export interface ForgotPasswordState {
  resetLinkSending: boolean,
  resetLinkSent: boolean,
  resetLinkError: boolean,
  resetEmail: string,
}

export interface ChangePasswordState {
  changePasswordSending: boolean,
  changePasswordError: boolean,
  changePasswordSuccess: boolean,
  changePasswordLinkExpired: boolean,
  invalidPassword: string | null,
}

/* **************** */
/* PLAYERS STATE */
/* **************** */

export interface Player {
  peerId: string;
  userId: string;
  uiState: PlayerUiState;
  displayName: string;
}

export interface Players { [userId: string]: Player }

export interface BitrateLevels {
  [userId: string]: {
    preference: keyof BitrateOptions | null,
    currentMaxBitrate: number | null,
    displayName: string,
    locked: boolean,
  }
}

/**
 * Keeps track of each user's current audio/video state (both player and encoder state).
 * This information is synced up in the VideoTileVideoPlayer and VideoTileVideoEncoder components.
 */
export interface MediaState {
  [userId: string]: {
    videoOff: boolean | null,
    audioOff: boolean | null,
  }
}

export interface VisibilityState {
  [userId: string]: boolean
}
export interface PlayersState {
  players: Players,
  screenSharePlayers: Players,
  playerBitrateLevels: BitrateLevels,
  mediaState: MediaState,
  visibility: VisibilityState
  isHighCPU: boolean,
}

/* ******************* */
/* ERROR MESSAGE STATE */
/* ******************* */

export interface ErrorMessageState {
  errorPage: string,
  launchGroups: string,
  room: string,
  join: string,
  breakoutGroups: string,
  timeout: string,
  device: string,
  attendance: string,
  incompatibleError: string,
}

/**
 * These two states are split into separate interfaces because they are two separate but related
 * pages, and each of their states can be reset separately from one another in the reducer.
 */
export type PasswordResetState = ForgotPasswordState & ChangePasswordState;

/* *********** */
/* DEBUG STATE */
/* *********** */

export type BitrateOptions = {
  [key in 'Low' | 'Medium' | 'High']: {
    maxBitrate: number,
  }
}

export interface DebugState {
  encoderBitrateOptions: BitrateOptions,
  isAudioDTX: boolean,
  codecEnabled: boolean,
  isAudioDebugModalOpen: boolean,
  isBitrateOptionsModalOpen: boolean,
  isDebugModalOpen: boolean,
  isGrafanaOptionsModalOpen: boolean,
  isFiltersModalOpen: boolean,
  enableBitrateLevels: boolean,
  enableGrafanaLinks: boolean,
  enableMockParticipants: boolean,
  enableSandbox: boolean,
  enableDeleteAllRooms: boolean,
  enableArcade: boolean,
  enableChalkboardStream: boolean,
  enableEmitVideoClientStats: boolean,
  enableSmartTool: boolean
  enableBitrateSwitching: boolean,
}

export interface AudioState {
  soundPlayer: SoundPlayer,
  soundPlayerOptions: SoundPlayerOptions,
}

/* *********** */
/* GIPHY STATE */
/* *********** */

/** Map of gif ID to result data, for caching non-searched GIFs */
export interface GifData {
  [gifId: string]: GifResult | undefined,
}

export interface GiphyState {
  gifData: GifData,
  presetGifs: GifsResult | null,
}

/* ************* */
/* MOBILE STATE */
/* ************* */

export interface MobileState {
  dismissedMobileRedirect: boolean,
}

/* **************** */
/* CHALKBOARD STATE */
/* **************** */

export type ChalkboardTip = 'smartShape'

/** Identifies the user's selected color. The real color value is determined based on both the ChalkboardColor and ChalkboardTheme */
export type ChalkboardColor = 'primary' | 'blue' | 'green' | 'red' | 'purple' | 'orange'

export type ChalkboardTheme = 'dark' | 'light'

export type ChalkboardTemplate = 'gridSmall' | 'gridLarge' | 'dotsSmall' | 'dotsLarge' |
  'vennDiagram' | 'notebook' | 'mapWorld' | 'mapUs' | 'mapAsia' | 'mapEurope' | 'periodicTable' |
  'musicGrandStaff' | 'musicGuitarTab'

export type ChalkboardToolMode = 'freeDraw' | 'line' | 'rectangle' | 'ellipse' | 'smartShape' | 'eraser' | 'text' | 'drag'

export interface ChalkboardState {
  isChalkboardOpen: boolean,
  tips: {
    [key in ChalkboardTip]: { dismissed: boolean }
  },
  theme: ChalkboardTheme,
  template: ChalkboardTemplate | null,
  tool: { mode: ChalkboardToolMode, color: ChalkboardColor, cursor: string }
  isAwaitingClear: boolean
  isDownloading: boolean
  committedItems: { [id: string]: ChalkboardItem }
}

/* ***************** */
/* ICEBREAKERS STATE */
/* ***************** */
export interface IcebreakerAnswer {
  label: string;
  value: string;
  correct: boolean;
}

export type IcebreakerQuestionType = 'multipleChoice' | 'trueFalse' | 'shortAnswer';
export interface IcebreakerQuestion {
  label: string;
  questionType: IcebreakerQuestionType;
  anonymousAnswers: boolean;
  answerCharLimit: number | null;
  answers: IcebreakerAnswer[] | null, // null if shortAnswer
}

export type IcebreakerIndexes = 0 | 1 | 2 | 3;
export interface IcebreakerParticipantResult {
  correct: boolean;
  answerId: number;
  response: string;
}

export interface IcebreakerPublishAnswer {
  label: string;
  value: string;
  id: number;
  correct?: boolean;
}

export interface IcebreakerPublishQuestion {
  active: boolean;
  created: Date;
  id: number;
  questionSessionId: string;
  label: string;
  questionType: 'multipleChoice' | 'trueFalse' | 'shortAnswer';
  anonymousAnswers: boolean;
  answerCharLimit: number;
  answers: { [idx: string]: IcebreakerPublishAnswer };
  results: { [idx: string]: IcebreakerParticipantResult };
}

export interface IcebreakerParticipantResponse {
  answerId: number; // 0 if shortAnswer
  response: string; // empty if not shortAnswer
}

export interface IcebreakerParticipantResponseNotification {
  questionId: number;
  answerId: number;
  response: string;
  userId: string;
}

export interface IcebreakerCalculatedResults {
  [index: number | string]: {
    id: number | string,
    count: number,
  }
}

export interface IcebreakerState {
  isIcebreakerOpen: boolean,
  loading: boolean,
  question: IcebreakerQuestion,
  activeResults: IcebreakerParticipantResponseNotification[],
  answeredIcebreakers: string[],
  activeCorrectAnswer: string,
  resultsDismissed: boolean,
  shortAnswer: string,
}

/* *********** */
/* NOTES STATE */
/* *********** */

export interface Note {
  created: string,
  updated: string,
  id: number,
  /* @todo update note type once confirmed if it should be stringified */
  note: string,
  sessionParticipantId: number,
  userId: number
}
export interface NotesState {
  isNotesOpen: boolean,
  note: null | Note,
  loading: boolean,
}

/* ************** */
/* COMBINED STATE */
/* ************** */

export interface StoreState {
  router: RouterState;
  browser: BrowserState;
  loginState: LoginState;
  joinState: JoinState;
  roomState: RoomState;
  nudgeState: NudgeState;
  chatState: ChatState;
  encoderState: EncoderState;
  screenShareState: ScreenShareState;
  filterState: FilterState,
  playersState: PlayersState;
  dashboardState: DashboardState;
  breakoutGroupsState: BreakoutGroupsState;
  alertState: AlertState,
  notificationState: NotificationState,
  errorMessageState: ErrorMessageState;
  attendanceState: AttendanceState;
  passwordResetState: PasswordResetState;
  preferencesState: PreferencesState;
  debugState: DebugState;
  audioState: AudioState;
  giphyState: GiphyState;
  networkState: NetworkState;
  mobileState: MobileState;
  chalkboardState: ChalkboardState;
  icebreakerState: IcebreakerState;
  notesState: NotesState;
}

/* for a good model on which we can base our Redux actions, see:
https://github.com/redux-utilities/flux-standard-action, which is
what Redux itself recommends in their own Action type docs */
export interface _AppAction<Payload = { [key: string]: any }> extends Action {
  type: string,
  payload?: Payload,
  error?: Error,
  meta?: { [key: string]: string }, // can be changed if we decide to use this
}

export type AppAction<Payload = { [key: string]: any }> = NoExtraProperties<_AppAction<Payload>>

export type AppThunkDispatch<
  DispatchableAction extends Action<any> = AppAction<any>,
  ExtraArgument = undefined,
  > = ThunkDispatch<StoreState, ExtraArgument, DispatchableAction>

export type AppThunkAction<
  ReturnType = void,
  DispatchableAction extends Action<any> = AppAction<any>,
  ExtraArgument = undefined,
  > = ThunkAction<ReturnType, StoreState, ExtraArgument, DispatchableAction>

/**
 * This is the default import type for `{ ReactComponent as SomeSvg }`-style imports.
 * It is an SVG that has had no `aria-` accessibility labels added to it.
 *
 * Typing unlabeled Svg components as `unknown` by default prevents us from accidentally trying
 * to render a plain Svg component without its <Svg /> component wrapper.
 *
 * Using the unique `Any.Type` with `unknown` also allows us to provide strong type
 * requirements for components like <Svg /> that accept an Svg ReactComponent as a prop.
 */
export type UnlabeledSvgComponent = Any.Type<unknown, 'SvgComponent'>;

/**
 * The "real" SVG type (or a close approximation of it). If you are using this type,
 * then you must ensure you are providing all necessary aria labels for the SVG you are rendering.
 */
export type LabeledSvgComponent = (props: ComponentProps<'svg'>) => ReactElement<ComponentProps<'svg'>, 'svg'>;

export type ISOString = string;
