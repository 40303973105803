const defaultConstraints = {
  defaultConstraints: {
    audio: {
      autoGainControl: { exact: true },
      channelCount: { min: 1, ideal: 2 },
      echoCancellation: { exact: true },
      latency: { ideal: 0 },
      noiseSuppression: { exact: true },
      sampleRate: { min: 48000, ideal: 48000, max: 48000 },
      sampleSize: { min: 16, ideal: 16 },
    },
    video: {
      facingMode: { ideal: 'user' },
      aspectRatio: { exact: 16 / 9 },
      height: { min: 720, ideal: 720, max: 720 },
      frameRate: { ideal: 24 },
    },
    screencapture: {
      frameRate: { ideal: 24 },
    },
  },
  fallbackConstraints: {
    audio: {
      autoGainControl: { ideal: true },
      channelCount: { min: 1, ideal: 2 },
      echoCancellation: { ideal: true },
      latency: { ideal: 0 },
      noiseSuppression: { ideal: true },
      sampleRate: { min: 48000, ideal: 48000, max: 48000 },
      sampleSize: { min: 16, ideal: 16 },
    },
    video: {
      facingMode: { ideal: 'user' },
      aspectRatio: { ideal: 16 / 9 },
      height: { min: 480, ideal: 480, max: 480 },
      frameRate: { ideal: 24 },
    },
    screencapture: {},
  },
  replaceTracks: true,
  waitingDelay: 100,
  defaultLockPolicy: 1,
};

export default defaultConstraints;
