import {
  EncoderUiState, types, mediaController, VideoClient as LvVideoClient, CallState,
} from '@video/video-client-web';
import { EncoderUiOptions } from '@video/video-client-web/lib/store/encoder/ui-state';
import logger, { createLogger } from 'utils/logger';

import retry from 'utils/retry';
import { PermissionEnum, PermissionStateEnum } from 'utils/browserPermissions';
import { setPlayerByProvider, removePlayerByProvider, PlayerStreamEnum } from 'actions/playersActions';
import {
  handleError, sendUserToErrorPage, setDeviceErrorMessage, setErrorMessage,
  setJoinError, setRoomError, resetAllMedia,
} from 'actions/sharedActions';
import {
  AppThunkAction, DeviceIdTypes, DominantSpeaker,
  MediaStreamController, VideoClient, UiState, Broadcast, EncoderRenderIdsEnum, AppThunkDispatch, StoreState,
} from 'store/types';
import { MakeActionType } from 'utils/typeUtils';
import { getMessageFromError } from 'utils/errorUtils';
// import mixpanel from 'utils/mixpanel';
import retrySync from 'utils/retrySync';
import { batch } from 'react-redux';
import { getHighestPriorityEnumValue, getVideoOrFilterMediaStreamController } from 'selectors';
import { getLocalStorageFeatureFlagValue } from 'hooks/useFeatureFlags';
import defaultConstraints from 'utils/encoder/defaultConstraints';
import createEncodingOptions from 'utils/encoder/encodings';
import resolutionOptions from 'utils/encoder/resolutions';
import {
  setDestCanvas, setFilter, setIsFiltering, setSrcVideo, setupFilterElements,
  startFiltering,
} from './filterActions';

export const MSC_FALLBACK_ERROR = { message: 'Error encoderActions.ts: mediaStreamController error' } as const;

export const reducerName = 'encoderState' as const;
export const SET_DEVICE_ID = `${reducerName}/SET_DEVICE_ID` as const;
export const SET_DEVICE_ID_PREF = `${reducerName}/SET_DEVICE_ID_PREF` as const;
export const MEDIA_CONTROLLER_INITIALIZED = `${reducerName}/MEDIA_CONTROLLER_INITIALIZED` as const;
export const SET_VIDEO_CLIENT = `${reducerName}/SET_VIDEO_CLIENT` as const;
export const SET_CALL = `${reducerName}/SET_CALL` as const;
export const SET_DEVICE_PERMISSION = `${reducerName}/SET_DEVICE_PERMISSION` as const;
export const SET_RETRYING_TO_CONNECT = `${reducerName}/SET_RETRYING_TO_CONNECT` as const;
export const SET_NUM_BITRATES = `${reducerName}/SET_NUM_BITRATES` as const;
export const SET_HAS_TRIED_UPSHIFT = `${reducerName}/SET_HAS_TRIED_UPSHIFT` as const;
export const SET_UPSHIFT_UNSUCCESSFUL_TIME = `${reducerName}/SET_UPSHIFT_UNSUCCESSFUL_TIME` as const;
export const SET_DOMINANT_SPEAKER = `${reducerName}/SET_DOMINANT_SPEAKER` as const;

// video encoder actions
export const SET_VIDEO_MEDIA_STREAM_CONTROLLER = `${reducerName}/SET_VIDEO_MEDIA_STREAM_CONTROLLER` as const;
export const SET_VIDEO_MEDIA_STREAM_CONTROLLER_LOADING = `${reducerName}/SET_MSC_LOADING` as const;
export const SET_VIDEO_MEDIA_STREAM_CONTROLLER_ERROR = `${reducerName}/SET_MEDIA_CONTROLLER_ERROR` as const;
export const SET_VIDEO_PAUSED = `${reducerName}/SET_VIDEO_PAUSED` as const;
export const SET_VIDEO_UI_STATE = `${reducerName}/SET_UI_STATE` as const;

// filter encoder actions
export const SET_AUDIO_MUTED = `${reducerName}/SET_AUDIO_MUTED` as const;
export const SET_BROADCAST = `${reducerName}/SET_BROADCAST` as const;
export const SET_FILTER_MEDIA_STREAM_CONTROLLER = `${reducerName}/SET_FILTER_MEDIA_STREAM_CONTROLLER` as const;
export const SET_FILTER_MEDIA_STREAM_CONTROLLER_LOADING = `${reducerName}/SET_FILTER_MEDIA_STREAM_CONTROLLER_LOADING` as const;
export const SET_FILTER_UI_STATE = `${reducerName}/SET_FILTER_UI_STATE` as const;
export const SET_FILTER_MEDIA_STREAM_CONTROLLER_ERROR = `${reducerName}/SET_FILTER_MEDIA_STREAM_CONTROLLER_ERROR` as const;
export const SET_FILTER_PORTAL_ID = `${reducerName}/SET_FILTER_PORTAL_ID` as const;
export const SET_FILTER_MAX_BITRATE = `${reducerName}/SET_FILTER_MAX_BITRATE` as const;

/** It is not possible to simulcast with screenshare */
export const FILTER_DEFAULT_MAX_BITRATE = 75_000;
export const FILTER_STREAM_FPS = 24;

export const setVideoPaused = (isVideoPaused: boolean) => ({
  type: SET_VIDEO_PAUSED,
  payload: { isVideoPaused },
});

export const setVideoMediaStreamController = (
  mediaStreamController: MediaStreamController,
) => ({
  type: SET_VIDEO_MEDIA_STREAM_CONTROLLER,
  mediaStreamController,
});

export const setVideoMediaStreamControllerLoading = (mediaStreamControllerLoading: boolean) => ({
  type: SET_VIDEO_MEDIA_STREAM_CONTROLLER_LOADING,
  payload: { mediaStreamControllerLoading },
});

export const setVideoUiState = (encoderUiState: UiState) => ({
  type: SET_VIDEO_UI_STATE,
  payload: { encoderUiState },
});

export const setDeviceId = (deviceType: DeviceIdTypes, deviceId: string) => ({
  type: SET_DEVICE_ID,
  payload: {
    deviceType,
    deviceId,
  },
});

export const setDeviceIdPref = (deviceType: DeviceIdTypes, deviceId: string) => ({
  type: SET_DEVICE_ID_PREF,
  payload: {
    deviceType,
    deviceId,
  },
});

export const setVideoMediaStreamControllerError = (error: string | null) => ({
  type: SET_VIDEO_MEDIA_STREAM_CONTROLLER_ERROR,
  payload: { error },
});

export const setVideoClient = (videoClient: VideoClient) => ({
  type: SET_VIDEO_CLIENT,
  payload: { videoClient },
});

export const setCallState = (callState: CallState | null) => ({
  type: SET_CALL,
  payload: { callState },
});

export const setBroadcast = (broadcast: Broadcast) => ({
  type: SET_BROADCAST,
  payload: { broadcast },
});

export const setDevicePermission = (devicePermission: PermissionStateEnum) => ({
  type: SET_DEVICE_PERMISSION,
  payload: { devicePermission },
});

export const setRetryingToConnect = (isRetrying: boolean) => ({
  type: SET_RETRYING_TO_CONNECT,
  payload: { isRetrying },
});

export const setNumBitrates = (numBitrates: 1 | 2 | 3) => ({
  type: SET_NUM_BITRATES,
  payload: { numBitrates },
});

export const setHasTriedUpshift = (hasTriedUpshift: boolean) => ({
  type: SET_HAS_TRIED_UPSHIFT,
  payload: { hasTriedUpshift },
});

export const setUpshiftUnsuccessfulTime = (upshiftUnsuccessfulTime: number) => ({
  type: SET_UPSHIFT_UNSUCCESSFUL_TIME,
  payload: { upshiftUnsuccessfulTime },
});

export const setFilterMediaStreamController = (mediaStreamController: MediaStreamController) => ({
  type: SET_FILTER_MEDIA_STREAM_CONTROLLER,
  payload: { mediaStreamController },
});

export const setFilterUiState = (filterUiState: UiState) => ({
  type: SET_FILTER_UI_STATE,
  payload: { filterUiState },
});

export const setFilterMediaStreamControllerError = (error: string) => ({
  type: SET_FILTER_MEDIA_STREAM_CONTROLLER_ERROR,
  payload: { error },
});

export const setFilterMediaStreamControllerLoading = (loading: boolean) => ({
  type: SET_FILTER_MEDIA_STREAM_CONTROLLER_LOADING,
  payload: { loading },
});

/**
 * When two different components try to render the user's filtered encoder video at the same time,
 * the component whose id matches the encoderRenderId is the one that gets to render (so that we dont attempt to
 * render them in 2 places at once.
 */
export const _setEncoderRenderId = (encoderRenderId: keyof typeof EncoderRenderIdsEnum, isRendering: boolean) => ({
  type: SET_FILTER_PORTAL_ID,
  payload: {
    encoderRenderId, isRendering,
  },
});

export const setAudioMuted = (muted: boolean) => ({
  type: SET_AUDIO_MUTED,
  payload: { muted },
});

export const setFilterMaxBitrate = (bitrate: number) => ({
  type: SET_FILTER_MAX_BITRATE,
  payload: { bitrate },
});

export const setDominantSpeaker = (dominantSpeaker: DominantSpeaker) => ({
  type: SET_DOMINANT_SPEAKER,
  payload: { dominantSpeaker },
});

export type EncoderAction = MakeActionType<[
  typeof MEDIA_CONTROLLER_INITIALIZED,
  typeof setAudioMuted,
  typeof _setEncoderRenderId,
  typeof setBroadcast,
  typeof setFilterMediaStreamController,
  typeof setFilterUiState,
  typeof setFilterMediaStreamControllerError,
  typeof setFilterMediaStreamControllerLoading,
  typeof setVideoPaused,
  typeof setVideoMediaStreamController,
  typeof setVideoMediaStreamControllerLoading,
  typeof setVideoUiState,
  typeof setDeviceId,
  typeof setVideoMediaStreamControllerError,
  typeof setVideoClient,
  typeof setCallState,
  typeof setDevicePermission,
  typeof setRetryingToConnect,
  typeof setNumBitrates,
  typeof setHasTriedUpshift,
  typeof setUpshiftUnsuccessfulTime,
  typeof setFilterMaxBitrate,
  typeof resetAllMedia,
  typeof setDominantSpeaker,
  typeof setDeviceIdPref,
]>

/** Wrapper around _setEncoderRenderId for enum key conversion and logging purposes */
export const setEncoderRenderId = (encoderRenderId: EncoderRenderIdsEnum, isRendering: boolean): AppThunkAction => (dispatch, getState) => {
  const portalIdAsKeyString = EncoderRenderIdsEnum[encoderRenderId] as keyof typeof EncoderRenderIdsEnum;

  const prevPortalIds = getState().encoderState.encoderRenderIds;
  const nextPortalIds = {
    ...prevPortalIds,
    [portalIdAsKeyString]: isRendering,
  };

  /** This is the encoderRenderId that should now be rendering */
  const updatedHighestPriorityPortalId = getHighestPriorityEnumValue(nextPortalIds);
  logger.debug(`setEncoderRenderId ${portalIdAsKeyString}=${isRendering}`, {
    prevPortalIds,
    nextPortalIds,
    portalIdBeingSet: portalIdAsKeyString,
    updatedHighestPriorityPortalId,
    isRendering,
  });

  dispatch(_setEncoderRenderId(portalIdAsKeyString, isRendering));
};

export const setEncoderAudioMuted = (muteAudio: boolean): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const mediaStreamController = getVideoOrFilterMediaStreamController(state);
  const { devicePermission } = state.encoderState;
  if (devicePermission !== PermissionStateEnum.GRANTED) {
    logger.warn('Cannot set encoder audio', { reason: 'Permission not granted' });
    return;
  }
  if (!mediaStreamController) {
    logger.warn('Cannot set encoder audio', { reason: 'No mediaStreamController present' });
    return;
  }
  const { audioMuted } = mediaStreamController;

  logger.info(muteAudio ? 'User muted' : 'User unmuted');

  dispatch(setAudioMuted(muteAudio));

  // mixpanel.track(muteAudio ? 'Audio Off' : 'Audio On');

  if (muteAudio !== audioMuted) {
    mediaStreamController.audioMuted = muteAudio;
  }
};

export const setEncoderVideoPaused = (pauseVideo: boolean): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const { videoMediaStreamController: vMSC, filterMediaStreamController: fMSC } = state.encoderState;
  const { devicePermission, videoDeviceIdPref } = state.encoderState;
  const enableFilters = getLocalStorageFeatureFlagValue('enableFilters');

  if (devicePermission !== PermissionStateEnum.GRANTED) {
    logger.warn('Cannot set encoder video pause state', { reason: 'Permission not granted' });
    return;
  }
  if (!vMSC) {
    logger.warn('Cannot set encoder video pause state', { reason: 'No video mediaStreamController present' });
    return;
  }
  if (enableFilters && !fMSC) {
    logger.warn('Cannot set encoder video pause state', { reason: 'Filters enabled and no filter MSC is present' });
    return;
  }
  const msc = enableFilters ? fMSC : vMSC;
  if (!msc) {
    logger.warn('Cannot set encoder video pause state', { reason: 'Filters enabled and no filter MSC is present' });
    return;
  }

  logger.info(pauseVideo ? 'User video off' : 'User video on');
  // mixpanel.track(pauseVideo ? 'Video Off' : 'Video On');

  const { videoDisabled } = msc;
  dispatch(setVideoPaused(pauseVideo));

  if (pauseVideo !== videoDisabled) {
    msc.videoDisabled = pauseVideo;
  }

  retrySync(() => getDevice('video'), {})
    .then((videoDevices) => {
      const currentAudioDevice = (videoDevices as types.MediaDeviceInfo[])
        .find(({ deviceId }) => deviceId === videoDeviceIdPref);

      if (!pauseVideo && currentAudioDevice) msc.videoDeviceId = videoDeviceIdPref;
    })
    .catch((videoDevices) => { logger.warn('No videoDevices found', { videoDevices }); });
};

/**
 * Gets the user's audio or video devices and throws an error if none are found
 * @param deviceType
 */
const getDevice = (deviceType: 'audio' | 'video'): readonly types.MediaDeviceInfo[] => {
  let devices: readonly types.MediaDeviceInfo[] = [];
  if (deviceType === 'audio') {
    devices = mediaController.audioDevices();
  }
  if (deviceType === 'video') {
    devices = mediaController.videoDevices();
  }
  if (devices.length) {
    return devices;
  }
  throw new Error(`No ${deviceType} devices found`);
};

export const getDevices = async () => {
  let audioDevices: types.MediaDeviceInfo[] = [];
  let videoDevices: types.MediaDeviceInfo[] = [];
  let message = '';

  try {
    audioDevices = await retrySync(() => getDevice('audio'), {}) as types.MediaDeviceInfo[];
  } catch (error) {
    logger.warn('No audioDevices found', { audioDevices });
  }

  try {
    videoDevices = await retrySync(() => getDevice('video'), {}) as types.MediaDeviceInfo[];
  } catch (error) {
    logger.warn('No videoDevices found', { videoDevices });
  }

  if (!videoDevices.length || !audioDevices.length) {
    if (!videoDevices.length && !audioDevices.length) {
      message = 'microphone and camera';
    } else if (!videoDevices.length) {
      message = 'camera';
    } else if (!audioDevices.length) {
      message = 'microphone';
    }
  }

  return { audioDevices, videoDevices, message };
};

/**
 * Determines if a device ID exists within the users device options
 * If it does exist - return the ID
 * If it does not exist - return the ID of the first device
 * If no devices - return null
 */
const checkDeviceId = async (reduxId: string | null, deviceType: 'video' | 'audio') => {
  let resultId = null;
  const { audioDevices, videoDevices } = await getDevices();
  let devices: readonly MediaDeviceInfo[] = [];
  if (deviceType === 'video') {
    devices = videoDevices;
  } else if (deviceType === 'audio') {
    devices = audioDevices;
  }

  if (reduxId && devices.find(({ deviceId }) => deviceId === reduxId)) {
    resultId = reduxId;
  } else if (devices.length > 0) {
    resultId = devices[0].deviceId;
  }
  return resultId;
};

const makeSourceChangedHandler = ({ getState }: {
  getState: () => StoreState
}) => (src: types.MediaStream) => {
  const filtersFeatureFlagEnabled = getLocalStorageFeatureFlagValue('enableFilters');

  // only relevant if Filters are currently enabled
  if (!filtersFeatureFlagEnabled) return;

  logger.debug('videoMediaStreamController sourceChanged');

  // update srcVideo element's srcObject to use new video stream
  const { filterState: { srcVideo } } = getState();
  if (srcVideo && src) {
    try {
      logger.debug('Updating Filter srcVideo stream source after device change');
      srcVideo.srcObject = src as MediaStream;
    } catch (error) {
      const errorMessage = getMessageFromError(error);
      logger.error('Error updating Filter srcVideo stream source', { errorMessage });
    }
  } else {
    logger.warn(
      'videoMediaStreamController\'s source changed, but the handler callback could not get srcVideo, or the new source was null',
      { filtersFeatureFlagEnabled },
    );
  }
};

const makeVideoDeviceChangedHandler = ({ dispatch, getState }: {
  dispatch: AppThunkDispatch,
  getState: () => StoreState,
}) => async (vidDevice: types.MediaStreamControllerEvents['videoDeviceChanged']) => {
  const {
    encoderState: {
      // audioDeviceIdPref,
      // videoDeviceIdPref,
      isVideoPaused,
    },
  } = getState();
  // const { audioDevices, videoDevices } = await getDevices();
  // const currentAudioDevice = audioDevices.find(({ deviceId }) => deviceId === audioDeviceIdPref);
  // const currentVideoDevice = videoDevices.find(({ deviceId }) => deviceId === videoDeviceIdPref);

  // if (vidDevice?.label && vidDevice.deviceId !== currentVideoDevice?.deviceId) {
  //   if (currentVideoDevice) { // only track as event if explicitly changed
  //     mixpanel.track('Preference Change', {
  //       Camera: vidDevice.label,
  //       Microphone: currentAudioDevice?.label || audioDevices[0]?.label || 'Default',
  //       Speakers: 'Default',
  //     });
  //   }
  //   mixpanel.setProfile({
  //     Camera: vidDevice.label,
  //   });
  // }

  const newVidId = vidDevice?.deviceId || '';
  logger.debug('Changing videoDeviceId', { videoDeviceId: newVidId });
  if (isVideoPaused && newVidId) {
    // if video is off, only update the preference
    dispatch(setDeviceIdPref('videoDeviceId', newVidId));
  } else if (!isVideoPaused) {
    dispatch(setDeviceId('videoDeviceId', newVidId));
  }
};

const makeAudioDeviceChangedHandler = ({ dispatch, getState: _ }: {
  getState: () => StoreState,
  dispatch: AppThunkDispatch,
}) => async (audDevice: types.MediaStreamControllerEvents['audioDeviceChanged']) => {
  // const {
  //   encoderState: {
  //     audioDeviceIdPref,
  //     videoDeviceIdPref,
  //   },
  // } = getState();
  // const { audioDevices, videoDevices } = await getDevices();
  // const currentAudioDevice = audioDevices.find(({ deviceId }) => deviceId === audioDeviceIdPref);
  // const currentVideoDevice = videoDevices.find(({ deviceId }) => deviceId === videoDeviceIdPref);

  // if (audDevice?.label && audDevice.deviceId !== currentAudioDevice?.deviceId) {
  //   if (currentAudioDevice) { // only track as event if explicitly changed
  //     mixpanel.track('Preference Change', {
  //       Camera: currentVideoDevice?.label || videoDevices[0]?.label || 'Default',
  //       Microphone: audDevice.label,
  //       Speakers: 'Default',
  //     });
  //   }
  //   mixpanel.setProfile({
  //     Microphone: audDevice.label,
  //   });
  // }
  logger.debug('Changing audioDeviceId', { audioDeviceId: audDevice?.deviceId || '' });
  dispatch(setDeviceId('audioDeviceId', audDevice?.deviceId || ''));
};

const makeChangeDevicesErrorHandler = ({ dispatch }: {
  dispatch: AppThunkDispatch
}) => (
  changeDevicesError: types.MediaStreamControllerEvents['changeDevicesError'],
) => {
  const errorMessage = getMessageFromError(changeDevicesError);
  logger.warn('MediaStreamController changeDeviceError', { errorMessage });
  const { err, audio, video } = changeDevicesError;

  // set error message
  dispatch(setDeviceErrorMessage(err.message));

  // set back told previous device
  if (audio && audio.old) {
    dispatch(setDeviceId('audioDeviceId', audio.old.deviceId));
  }
  if (video && video.old) {
    dispatch(setDeviceId('videoDeviceId', video.old.deviceId));
  }
};

const makeMscErrorHandler = ({ dispatch }: {
  dispatch: AppThunkDispatch
}) => (error: types.MediaStreamControllerEvents['error']) => {
  const errorMessage = error.toJSON();
  logger.warn('MediaStreamController error', { errorMessage });
  dispatch(setDeviceErrorMessage(error.message));
};

/** Logs when an unexpected event is received for a mediaStreamController */
const makeUnexpectedEventHandler = (eventName: keyof types.MediaStreamControllerEvents, type: 'video' | 'filter') => (
  e: types.MediaStreamControllerEvents[keyof types.MediaStreamControllerEvents],
) => {
  logger.debug(`Ignoring ${eventName} event received for ${type}MediaStreamController`, { e: e?.toString() });
};

export const addEventListenersToMediaStreamControllers = (
  videoMsc: types.MediaStreamController,
  filterMsc?: types.MediaStreamController | null,
): AppThunkAction<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const {
    audioDeviceIdPref,
    videoDeviceIdPref,
  } = state.encoderState;

  const { audioDevices, videoDevices, message } = await getDevices();
  if (!audioDevices.length || !videoDevices.length) {
    dispatch(setJoinError(`No ${message} found`));
  }
  logger.debug('Audio devices', { audioDevices });
  logger.debug('Video devices', { videoDevices });

  const currentAudioDevice = audioDevices.find(({ deviceId }) => deviceId === audioDeviceIdPref);
  const currentVideoDevice = videoDevices.find(({ deviceId }) => deviceId === videoDeviceIdPref);

  logger.debug('Current audio and video device', { currentAudioDevice, currentVideoDevice });

  const handleVideoDeviceChanged = makeVideoDeviceChangedHandler({ dispatch, getState });
  const handleAudioDeviceChanged = makeAudioDeviceChangedHandler({ dispatch, getState });
  const handleMscError = makeMscErrorHandler({ dispatch });
  // video media stream controller doesn't need audio if filters are enabled
  const handleVideoMscAudioDeviceChanged = filterMsc
    ? makeUnexpectedEventHandler('audioDeviceChanged', 'video')
    : handleAudioDeviceChanged;
  const handleChangeDevicesError = makeChangeDevicesErrorHandler({ dispatch });
  // only need to update source for video element if filters are enabled
  const handleSourceChanged = filterMsc
    ? makeSourceChangedHandler({ getState })
    : makeUnexpectedEventHandler('source', 'video');

  videoMsc.on('videoDeviceChanged', handleVideoDeviceChanged);
  videoMsc.on('source', handleSourceChanged);
  videoMsc.on('audioDeviceChanged', handleVideoMscAudioDeviceChanged);
  videoMsc.on('changeDevicesError', handleChangeDevicesError);
  videoMsc.on('error', handleMscError);
  videoMsc.once('disposed', () => {
    videoMsc.removeAllListeners();
    videoMsc.off('source', handleSourceChanged);
    videoMsc.off('videoDeviceChanged', handleVideoDeviceChanged);
    videoMsc.off('changeDevicesError', handleChangeDevicesError);
    videoMsc.off('error', handleMscError);
    videoMsc.off('audioDeviceChanged', handleVideoMscAudioDeviceChanged);
  });

  if (filterMsc) {
    // canvas only receives video from video media stream controller, but controls its own audio
    const handleFilterMscVideoDeviceChanged = makeUnexpectedEventHandler('videoDeviceChanged', 'filter');
    filterMsc.on('videoDeviceChanged', handleFilterMscVideoDeviceChanged);
    filterMsc.on('audioDeviceChanged', handleAudioDeviceChanged);
    filterMsc.on('changeDevicesError', handleChangeDevicesError);
    filterMsc.on('error', handleMscError);
    filterMsc.once('disposed', () => {
      filterMsc.off('videoDeviceChanged', handleFilterMscVideoDeviceChanged);
      filterMsc.off('audioDeviceChanged', handleAudioDeviceChanged);
      filterMsc.off('changeDevicesError', handleChangeDevicesError);
      filterMsc.off('error', handleMscError);
    });
  }
};

export const initMediaController = (): AppThunkAction<Promise<void>> => async (dispatch) => {
  try {
    await mediaController.init({ updateByTimer: 500 });

    logger.debug('MediaController initialized');

    dispatch({ type: MEDIA_CONTROLLER_INITIALIZED });
    dispatch(setDevicePermission(PermissionStateEnum.GRANTED));
  } catch (error) {
    dispatch(setVideoMediaStreamControllerLoading(false));
    const errorMessage = getMessageFromError(error);
    if (errorMessage === 'Permission denied') {
      // user denied access to mic and camera
      logger.info('User denied device permission', { errorMessage });
      dispatch(setDevicePermission(PermissionStateEnum.DENIED));
    } else {
      dispatch(handleError('Error initializing mediaController', { error }));
      dispatch(setVideoMediaStreamControllerError('Error initializing mediaController'));
    }
  }
};

const chooseInitialDevices = (usingFilters: boolean): AppThunkAction<Promise<{
  videoEncoderUiOptions: EncoderUiOptions,
  filterEncoderUiOptions: EncoderUiOptions,
}>> => async (_, getState) => {
  const state = getState();
  const { videoDeviceIdPref, audioDeviceIdPref, isVideoPaused } = state.encoderState;

  const videoEncoderUiOptions: EncoderUiOptions = { audioDevice: null, videoDevice: null };
  const filterEncoderUiOptions: EncoderUiOptions = { videoDevice: 'capturable' };

  const videoId = await checkDeviceId(videoDeviceIdPref, 'video');
  const audioId = await checkDeviceId(audioDeviceIdPref, 'audio');

  videoEncoderUiOptions.videoDevice = isVideoPaused ? null : videoId;

  if (usingFilters) {
    filterEncoderUiOptions.audioDevice = audioId;
  } else {
    videoEncoderUiOptions.audioDevice = audioId;
  }

  return { videoEncoderUiOptions, filterEncoderUiOptions };
};

/** Set the initial play/pause states of the videoMediaStreamController and filterMediaStreamController */
const setMediaStreamControllersPlayPauseState = (
  videoMsc: types.MediaStreamController,
  filterMsc?: types.MediaStreamController | null,
): AppThunkAction => (_, getState) => {
  const {
    encoderState: {
      isAudioMuted,
      isVideoPaused,
    },
  } = getState();

  // if no setting has been specified in state, do not mute/pause by default
  const newAudioMuted = isAudioMuted ?? false;
  const newVideoPaused = isVideoPaused ?? false;

  if (filterMsc) {
    // when filters ARE enabled, we get audio from filterMsc
    filterMsc.audioMuted = newAudioMuted;
    videoMsc.audioMuted = true;

    // when filters ARE enabled, videoMsc should never be paused
    filterMsc.videoDisabled = newVideoPaused;
    videoMsc.videoDisabled = false;
  } else {
    // when filters are NOT enabled, we get both audio and video from videoMsc
    videoMsc.audioMuted = newAudioMuted;
    videoMsc.videoDisabled = newVideoPaused;
  }
};

/**
 * When filters are enabled:
 * Creates a videoMediaStreamController for the user's regular, unfiltered video and also a filterMediaStreamController for the user's filtered video
 *
 * When filters are NOT enabled:
 * Creates only a videoMediaStreamController for the user's regular, unfiltered video
 * */
export const createMediaStreamControllers = (): AppThunkAction<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const {
    videoMediaStreamControllerLoading,
    filterMediaStreamControllerLoading,
    mediaControllerInitialized,
    videoMediaStreamController: prevVideoMediaStreamController,
    filterMediaStreamController: prevFilterMediaStreamController,
  } = state.encoderState;

  if (prevVideoMediaStreamController || prevFilterMediaStreamController) {
    logger.error('Media Stream Controller already exists', {
      videoMediaStreamController: !!prevVideoMediaStreamController,
      filterMediaStreamController: !!prevFilterMediaStreamController,
    });
    return;
  }

  const filtersEnabled = getLocalStorageFeatureFlagValue('enableFilters');

  if (videoMediaStreamControllerLoading) {
    logger.debug('Cannot create mediaStreamController', {
      reason: 'videoMediaStreamControllerLoading already loading',
      videoMediaStreamControllerLoading,
      filtersEnabled,
    });
    return;
  }
  if (filtersEnabled && filterMediaStreamControllerLoading) {
    logger.debug('Cannot create mediaStreamController', {
      reason: 'filterMediaStreamControllerLoading already loading',
      filterMediaStreamControllerLoading,
      filtersEnabled,
    });
    return;
  }

  if (filtersEnabled) {
    logger.debug('Creating videoMediaStreamController and filterMediaStreamController');
  } else {
    logger.debug('Creating a single videoMediaStreamController');
  }

  batch(() => {
    dispatch(setVideoMediaStreamControllerLoading(true));
    dispatch(setVideoMediaStreamControllerError(null));
    if (filtersEnabled) {
      dispatch(setFilterMediaStreamControllerLoading(true));
      dispatch(setFilterMediaStreamControllerError(''));
    }
  });

  if (!mediaControllerInitialized) {
    logger.debug('Initializing mediaController');
    await dispatch(initMediaController());
  }

  try {
    logger.debug('Creating videoMediaStreamController');
    const videoMediaStreamController = await mediaController.requestController(defaultConstraints);
    logger.debug('videoMediaStreamController created');

    let filterMediaStreamController: types.MediaStreamController | null = null;
    let srcVideo: HTMLVideoElement | null = null;
    let destCanvas: HTMLCanvasElement | null = null;
    if (filtersEnabled) {
      logger.debug('Creating filter elements');
      const filterElements = await setupFilterElements(videoMediaStreamController);
      srcVideo = filterElements.srcVideo;
      destCanvas = filterElements.destCanvas;
      logger.debug('Filter elements created');

      logger.debug('Creating filterMediaStreamController');
      filterMediaStreamController = await mediaController.requestController({
        capturable: { element: destCanvas, framerate: FILTER_STREAM_FPS },
      });
      logger.debug('filterMediaStreamController created');
    }

    dispatch(setMediaStreamControllersPlayPauseState(videoMediaStreamController, filterMediaStreamController));

    const { videoEncoderUiOptions, filterEncoderUiOptions } = await dispatch(chooseInitialDevices(filtersEnabled));

    // event listeners should be added before setting encoder ui states to state due to race conditions
    await dispatch(addEventListenersToMediaStreamControllers(videoMediaStreamController, filterMediaStreamController));

    batch(() => {
      dispatch(setVideoMediaStreamController(videoMediaStreamController));
      logger.debug('videoMediaStreamController set to redux');
      logger.debug('Creating videoEncoderUiState');
      const videoEncoderUiState = new EncoderUiState(videoMediaStreamController, videoEncoderUiOptions);
      logger.debug('videoEncoderUiState created');
      dispatch(setVideoUiState(videoEncoderUiState));
      logger.debug('videoEncoderUiState set to redux');

      if (filtersEnabled) {
        if (!filterMediaStreamController || !srcVideo || !destCanvas) {
          const errorMessage = 'Filters are enabled, but state required for filtering was not set up correctly';
          logger.error(errorMessage, {
            filtersEnabled,
            canvasMediaStreamControllerDefined: !!filterMediaStreamController,
            srcVideoDefined: !!srcVideo,
            destCanvasDefined: !!destCanvas,
          });
          throw new Error(errorMessage);
        }

        dispatch(setFilterMediaStreamController(filterMediaStreamController));
        logger.debug('filterMediaStreamController set to redux');
        logger.debug('Creating filterEncoderUiState');
        const filterEncoderUiState = new EncoderUiState(filterMediaStreamController, filterEncoderUiOptions);
        logger.debug('filterEncoderUiState created');
        dispatch(setFilterUiState(filterEncoderUiState));
        logger.debug('filterEncoderUiState set to redux');
        dispatch(setSrcVideo(srcVideo));
        logger.debug('srcVideo set to redux');
        dispatch(setDestCanvas(destCanvas));
        logger.debug('destCanvas set to redux');
      }
    });

    if (filtersEnabled && srcVideo && destCanvas) {
      logger.debug('Starting filtering');
      await dispatch(startFiltering(srcVideo, destCanvas));
    }
  } catch (error) {
    const errorMessage = getMessageFromError(error);
    logger.warn('Error requesting mediaStreamController', { errorMessage });
    dispatch(setVideoMediaStreamControllerError('Error requesting videoMediaStreamController'));
    if (filtersEnabled) {
      dispatch(setFilterMediaStreamControllerError('Error requesting filterMediaStreamController'));
    }
  } finally {
    batch(() => {
      dispatch(setVideoMediaStreamControllerLoading(false));
      if (filtersEnabled) {
        dispatch(setFilterMediaStreamControllerLoading(false));
      }
    });
  }
};

/**
 * Dispose and reset encoderUiState and mediaStreamController.
 * Also disposes current filter state, since Filters are dependent on mediaStreamControllers.
 */
export const cleanUpMediaStreamControllers = (debugString: string): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const {
    encoderState: {
      videoMediaStreamController, videoUiState, filterMediaStreamController, filterUiState,
    }, filterState: {
      srcVideo,
    },
  } = state;
  const { filter } = state.filterState;

  logger.debug('Cleaning up mediaStreamControllers', { debugString });

  if (videoUiState) {
    videoUiState.dispose(debugString);
  }
  if (videoMediaStreamController) {
    videoMediaStreamController.dispose(debugString);
  }
  if (filter) {
    filter.cleanup();
  }
  if (srcVideo) {
    srcVideo.srcObject = null;
  }
  if (filterUiState) {
    filterUiState.dispose(debugString);
  }
  if (filterMediaStreamController) {
    filterMediaStreamController.dispose(debugString);
  }

  batch(() => {
    dispatch(setVideoMediaStreamController(null));
    dispatch(setVideoUiState(null));
    dispatch(setFilter(null));
    dispatch(setIsFiltering(false));
    dispatch(setDestCanvas(null));
    dispatch(setSrcVideo(null));
    dispatch(setFilterMediaStreamController(null));
    dispatch(setFilterUiState(null));
  });
};

export const listenForDevicePermissionChange = (): AppThunkAction => (dispatch, getState) => {
  const { browserPermissions } = getState().encoderState;
  if (!browserPermissions.isListening(PermissionEnum.CAMERA) && browserPermissions.available) {
    browserPermissions.listen(PermissionEnum.CAMERA, (permState) => {
      if (permState !== PermissionStateEnum.UNKNOWN) dispatch(setDevicePermission(permState));
    });
  }
};

export const stopListenForDevicePermissionChange = (): AppThunkAction => (_, getState) => {
  const { browserPermissions } = getState().encoderState;
  browserPermissions.stopListen();
};

export const setUpVideoClient = (): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const { enableEmitVideoClientStats } = state.debugState;
  const { livelyRoomToken, room: { id: roomId } } = state.roomState;
  const userId = state.loginState.user?.userId || '';

  if (!livelyRoomToken?.token) {
    logger.warn('Cannot setup VideoClient', { reason: 'No room token' });
    return;
  }

  if (!userId) {
    logger.warn('Cannot setup VideoClient', { reason: 'No user ID' });
    return;
  }

  if (!roomId) {
    logger.warn('Cannot setup VideoClient', { reason: 'No room ID' });
    return;
  }

  try {
    logger.info('Setting up video client', {
      backendEndpoints: [appConfig.pvcLoadbalancerHost],
    });

    const opts: types.VideoClientOptions = {
      backendEndpoints: [appConfig.pvcLoadbalancerHost],
      token: livelyRoomToken.token,
      logger: createLogger(logger, 'video-client'),
    };

    if (enableEmitVideoClientStats) {
      const stats = {
        app: 'Chalkcast',
        userId,
        statsInterval: 5000,
        streamId: roomId || '',
      };
      logger.info('Setting up video-client with client stats', stats);
      opts.stats = stats;
    }

    const videoClient = new LvVideoClient(opts);

    videoClient.on('playerAdded', (playerProvider) => {
      dispatch(setPlayerByProvider(playerProvider));
    });

    videoClient.on('playerRemoved', (playerProvider) => {
      dispatch(removePlayerByProvider(playerProvider, 'Received playerRemoved event from video client'));
    });

    videoClient.on('error', (error) => {
      const errorMessage = error.toJSON();
      if (!error.critical) {
        logger.warn('Non-critical Broadcast error: ', { errorMessage });
        return;
      }
      logger.error('VideoClient on error handler', { errorMessage });
      const { message } = error;
      dispatch(setRoomError(`Video Client error: ${message}`));
    });

    dispatch(setVideoClient(videoClient));
  } catch (error) {
    const errorMessage = getMessageFromError(error);
    logger.error('Error setting up video client', { errorMessage });
  }
};

export const setUpCall = (callId: string): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const currentUserId = state.loginState.user?.userId;
  const { isRetrying, videoClient } = state.encoderState;

  if (!currentUserId) {
    logger.warn('setUpCall: Cannot set up call', { reason: 'No currentUserId present', ...state.loginState.user });
    return;
  }

  if (!videoClient) {
    logger.warn('setUpCall: Cannot set up call without videoClient');
    return;
  }

  try {
    logger.info('Joining call', { callId });

    const options: types.JoinCallOptions = {
      wsReconnect: true,
    };

    await retry(async () => {
      const call = await videoClient.joinCall(callId, options);
      const callState = new CallState();
      callState.call = call;
      logger.info('Successfully joined call');
      callState.call.on('error', (err) => {
        const errorMessage = err.toJSON();
        if (!err.critical) {
          logger.warn('Non-critical Call error: ', { errorMessage });
          return;
        }
        logger.error('Call on error handler', { errorMessage });
        // set retrying true to display message to user
        dispatch(setRetryingToConnect(true));
        // clean up all video client state so the useEffect in Room.tsx will
        // be triggered to set it back up
        dispatch(cleanUpAllMedia(false, 'Call error handler reached'));
      });

      callState.call.on('dominantSpeaker', (speaker) => {
        logger.debug('Setting dominant speaker', { ...speaker });
        dispatch(setDominantSpeaker(speaker));
      });

      if (isRetrying) dispatch(setRetryingToConnect(false));
      dispatch(setCallState(callState));
    }, { retries: 20 });
  } catch (error) {
    const errorMessage = getMessageFromError(error);
    logger.error('Error joining call', { errorMessage });
    dispatch(setErrorMessage('Error joining call: maximum retries reached'));
    dispatch(sendUserToErrorPage());
  }
};

export const setUpBroadcast = (): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const {
    encoderState: {
      filterMaxBitrate,
      callState,
      videoMediaStreamController,
      filterMediaStreamController,
      numBitrates,
    },
    debugState: {
      encoderBitrateOptions: {
        Low,
        Medium,
        High,
      },
      codecEnabled,
      isAudioDTX,
    },
  } = state;
  const filtersFeatureFlagEnabled = getLocalStorageFeatureFlagValue('enableFilters');

  if (callState === null || callState.call === null) {
    logger.warn('setUpBroadcast: Cannot set up broadcast', { reason: 'No call present' });
    return;
  }

  const normalEncodings = [Low, Medium, High];

  const encodingOptions = createEncodingOptions(normalEncodings);

  const resolution = resolutionOptions[numBitrates];
  logger.debug('Setting resolution', { resolution });

  const options: types.BroadcastOptions = {
    streamName: filtersFeatureFlagEnabled ? PlayerStreamEnum.FILTERED_VIDEO : PlayerStreamEnum.DEVICES,
    videoProducerOptions: {
      encodings: encodingOptions[numBitrates],
    },
    audioProducerOptions: {
      codecOptions: {
        opusDtx: isAudioDTX,
      },
    },
  };

  if (codecEnabled && options.videoProducerOptions) {
    options.videoProducerOptions.codec = {
      kind: 'video',
      mimeType: 'video/VP8',
      clockRate: 90000,
    };
  }

  try {
    let broadcast = null;

    if (filtersFeatureFlagEnabled) {
      if (!filterMediaStreamController) {
        logger.warn('setUpBroadcast: Cannot set up broadcast', {
          reason: 'Filters are enabled but filterMediaStreamController is not present',
          filtersFeatureFlagEnabled,
          filterMediaStreamController,
        });
        return;
      }

      // set up as filtered video stream
      logger.debug('Setting up filtered video broadcast', { filterMaxBitrate });
      filterMediaStreamController.resolution = resolution;
      broadcast = await callState.call.broadcast(filterMediaStreamController, options);
    } else {
      if (!videoMediaStreamController) {
        logger.warn('setUpBroadcast: Cannot set up broadcast', {
          reason: 'Filters are not enabled and videoMediaStreamController is not present',
          filtersFeatureFlagEnabled,
          videoMediaStreamController,
        });
        return;
      }

      // set up as standard video stream
      logger.debug('Setting up standard video broadcast', { filterMaxBitrate });
      videoMediaStreamController.resolution = resolution;
      broadcast = await callState.call.broadcast(videoMediaStreamController, options);
    }

    if (!broadcast) {
      logger.error('Error starting broadcast', { broadcast });
      return;
    }

    broadcast?.on('error', (err) => {
      const errorMessage = err.toJSON();
      if (!err.critical) {
        logger.warn('Non-critical Broadcast error: ', { errorMessage });
        return;
      }
      logger.warn('Broadcast on error', { errorMessage });
      dispatch(cleanUpBroadcast('Broadcast error handler reached'));
    });

    dispatch(setBroadcast(broadcast));
  } catch (error) {
    const errorMessage = getMessageFromError(error);
    logger.error('Error starting broadcast', { errorMessage });
  }
};

/**
 * Disposes all all videoClient processes and listeners and sets videoClient null.
 *
 * shouldSetState is optional, since occasionally we want to batch setting the state
 * into a single call to prevent race conditions
 */
export const cleanUpVideoClient = (debugString: string, shouldSetState = true): AppThunkAction => (dispatch, getState) => {
  const { videoClient } = getState().encoderState;
  if (videoClient) {
    videoClient.removeAllListeners('playerAdded');
    videoClient.removeAllListeners('playerRemoved');
    videoClient.removeAllListeners('error');
    videoClient.dispose(debugString);
    if (shouldSetState) dispatch(setVideoClient(null));
  }
};

/**
 * Disposes the call and sets call null in state.
 *
 * shouldSetState is optional, since occasionally we want to batch setting the state
 * into a single call to prevent race conditions
 */
export const cleanUpCall = (debugString: string, shouldSetState = true): AppThunkAction => (dispatch, getState) => {
  const { callState } = getState().encoderState;
  if (callState !== null && callState.call !== null) {
    callState.call.removeAllListeners('error');
    callState.call.dispose(debugString);
    if (shouldSetState) dispatch(setCallState(null));
  }
};

/**
 * Disposes the broadcast and sets broadcast null in state.
 *
 * shouldSetState is optional, since occasionally we want to batch setting the state
 * into a single call to prevent race conditions
 */
export const cleanUpBroadcast = (debugString: string, shouldSetState = true): AppThunkAction => (dispatch, getState) => {
  const { broadcast } = getState().encoderState;

  logger.debug('Cleaning up broadcast', { debugString });

  if (broadcast) {
    broadcast.removeAllListeners('error');
    broadcast.dispose(debugString);
    if (shouldSetState) dispatch(setBroadcast(null));
  }
};

/**
 * Disposes broadcast, call, and videoClient, and sets their state to null.
 */
export const cleanUpAllMedia = (resetVideoClient = true, debugString: string): AppThunkAction => (dispatch, getState) => {
  logger.debug('Cleaning up call media', { debugString });

  /* do not set state within each thunk action,
  because they will all be updated at once in `resetAllMedia` */
  const SET_STATE = false;

  dispatch(cleanUpBroadcast(debugString, SET_STATE));

  dispatch(cleanUpCall(debugString, SET_STATE));

  if (resetVideoClient) {
    dispatch(cleanUpVideoClient(debugString, SET_STATE));
  }

  // dispose all players before reseting redux state
  const { players, screenSharePlayers } = getState().playersState;
  Object.keys(players).forEach((id) => {
    players[id].uiState.dispose();
  });

  Object.keys(screenSharePlayers).forEach((id) => {
    screenSharePlayers[id].uiState.dispose();
  });

  // Reset all media will reset VDC, Call, Broadcast, Players
  dispatch(resetAllMedia(resetVideoClient));
};

/**
 * Updates the number of bitrates and resolution that a user is broadcasting at
 */
export const shiftBitrateAndResolution = (numBitrates: 1 | 2 | 3): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const {
    encoderState: {
      broadcast,
      videoMediaStreamController,
      filterMediaStreamController,
    },
    debugState: {
      encoderBitrateOptions: {
        Low,
        Medium,
        High,
      },
    },
  } = state;

  if (!broadcast || !videoMediaStreamController) {
    return;
  }

  const normalEncodings = [Low, Medium, High];

  const encodingOptions = createEncodingOptions(normalEncodings);

  dispatch(setNumBitrates(numBitrates));

  videoMediaStreamController.resolution = resolutionOptions[numBitrates];

  if (filterMediaStreamController) {
    filterMediaStreamController.resolution = resolutionOptions[numBitrates];
  }

  logger.debug('Hot swapping producer bitrate values', { encodings: JSON.stringify(encodingOptions[numBitrates]) });
  await broadcast.hotswapProducer('video', { encodings: encodingOptions[numBitrates] });
};

export const hotSwapAudioProducer = (isAudioDTX: boolean): AppThunkAction => async (_dispatch, getState) => {
  const state = getState();
  const { broadcast } = state.encoderState;

  if (!broadcast) {
    return;
  }

  await broadcast.hotswapProducer('audio', {
    codecOptions: { opusDtx: isAudioDTX },
  });
};
